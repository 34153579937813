import { storePersist } from '~/lib/store-persist'
import { isNative } from '~/plugins/native/capacitor'

export default async function ({ $log, store, route, $network, req, redirect }) {
  // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
  if (route.path.startsWith('/maintenance') || route.path.startsWith('/inventory')) {
    return
  }

  // Notification emails contain the country code override so UK testers can signup to ZA accounts
  // if (route.query?.country) {
  //   $log.debug('Country override on startup', route.query.country)
  //   store.commit('app/setApiCountry', route.query.country)
  // }

  let app = store.getters['app/getApp']

  if (!app.isReady) {
    try {
      const pingResponse = await $network.checkConnection(true)

      // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
      if (pingResponse.isMaintenanceModeEnabled) {
        return
      }
    } catch (error) {
      // Stub - internet offline
    }

    if (isNative) {
      await storePersist.load(store)
    } else {
      store.dispatch('auth/loadExistingTokens')
    }

    // Re-fetch the app in case it was updated
    app = store.getters['app/getApp']
  }

  // Have we fetched our system status yet? Usually page load only
  // On native we cache the org details for a faster startup time
  if (app.isReady || (app.isNative && !!app.config.orgName && !!store.state.auth.activeOrganisationPlatformKey)) {
    // Already run default layout fetch, skipping initial setup
  } else {
    // Get our system status which tells us all about the user if auth, and the whitelabel info
    await store.dispatch('app/fetchStatus')

    app = store.getters['app/getApp']
  }

  if (isNative && !store.state.device.hasHiddenSplash) {
    const { SplashScreen } = await import('@capacitor/splash-screen')

    SplashScreen.hide()
    store.commit('device/setHasHiddenSplash')
  }

  const guestPaths = ['/auth', '/maintenance', '/logistics/quotes/invitation', '/carrier-onboarding']

  // If user isn't logged in redirect them to login page if the current path isn't a guest path
  if (!app.isAuth && !guestPaths.find(guestPath => route.path.includes(guestPath))) {
    store.commit('navigation/setUnauthenticatedPath', route.fullPath)

    return redirect('/auth/logout')
  }

  // If user is quickquote user without other services redirect them to quickquote
  if (app.isQuickQuoteUser) {
    let hasOtherServices = false

    Object.keys(app.permissions).forEach(service => {
      if (service !== 'logisticsQuickQuotes' && app.permissions[service].length) {
        hasOtherServices = true
      }
    })

    if (
      !hasOtherServices &&
      !route.path.includes('/logistics/quotes/quick-quote') &&
      !route.path.includes('/auth/logout')
    ) {
      return redirect('/logistics/quotes/quick-quote')
    }
  }

  if (app.isAuth && store.state.navigation.originalUnauthenticatedPath) {
    // If the user was previously trying to get somewhere let's redirect them to that path
    const originalPath = store.state.navigation.originalUnauthenticatedPath

    store.commit('navigation/setUnauthenticatedPath', null)

    return redirect(originalPath)
  }

  // We don't have an index page yet and each profile type has their own dashboard so let's redirect appropriately
  if (route.path === '/') {
    // When we eventually have a homepage this will check for isNative
    // if (!app.isAuth && app.isNative) {

    return redirect('/dashboard')
  }
}
