import {
  AdjustHarvestPlanFieldAreaRemainingSchema,
  CreateHarvestPlanLoadSchema,
  HarvestPlanDetailSchema,
  HarvestPlanLoadDetailSchema
} from '@hectare/platform.clients.inventory'

import { ApiInventory } from '~/lib/api-inventory'
import { composeSearchParams } from '~/lib/utility/url'

export class HarvestService extends ApiInventory {
  getPlans(): Promise<HarvestPlanDetailSchema[]> {
    return this.get(`/inventory/harvest-plans`)
  }

  getPlan(id: string): Promise<HarvestPlanDetailSchema> {
    return this.get(`/inventory/harvest-plans/${id}`)
  }

  createLoad(id: string, postModel: CreateHarvestPlanLoadSchema[]): Promise<HarvestPlanLoadDetailSchema> {
    return this.post(`/inventory/harvest-plans/${id}/loads`, postModel)
  }

  deleteLoad(harvestPlanId: string, loadIds: string[]) {
    return this.post(
      `/inventory/harvest-plans/${harvestPlanId}/loads/delete`,
      loadIds.map(loadId => ({ loadId }))
    )
  }

  getLoads(harvestPlanId?: string, config = { limit: 25, offset: 0 }): Promise<HarvestPlanLoadDetailSchema> {
    const searchParams = composeSearchParams({ harvestPlanId, ...config })

    return this.get(`/inventory/harvest-plans/loads?${searchParams}`)
  }

  adjustFieldRemainingArea(
    harvestPlanId: string,
    patchModel: AdjustHarvestPlanFieldAreaRemainingSchema[]
  ): Promise<HarvestPlanDetailSchema> {
    return this.post(`/inventory/harvest-plans/${harvestPlanId}/adjust-fields-area-remaining`, patchModel)
  }

  getHarvestHistory(filters = '') {
    return this.get(`/inventory/harvest-plans/history?${filters}`)
  }
}
