import { ApiInventory } from '~/lib/api-inventory'

export class LocationService extends ApiInventory {
  async fetchFieldsLocations(accessToken) {
    return await this.get(`/location/v1/fields/internal`)
  }

  fetchExternalFieldsLocations() {
    return this.get(`/location/v1/fields/external`)
  }
}
