import {
  ContractDeliverySchema,
  ContractDetail,
  ContractRollOverSchema,
  CreateContractDeliverySchema,
  CreateContractRequest,
  LinkContractSchema,
  PatchContractDeliverySchema,
  PatchContractPricingSchema,
  PatchContractRequest,
  PutContractFile,
  UnlinkContractSchema
} from '@hectare/platform.clients.trading'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'

export class SalesContractService extends ApiInventory {
  createSalesContract(postModel: CreateContractRequest): Promise<ContractDetail> {
    return this.post('/trading/contracts', postModel)
  }

  getSalesContract(id: string): Promise<ContractDetail> {
    return this.get(`/trading/contracts/${id}`)
  }

  editSalesContract(id: string, salesContract: PatchContractRequest): Promise<ContractDetail> {
    return this.patch(`/trading/contracts/${id}`, salesContract)
  }

  deleteSalesContract(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/trading/contracts/${id}`)
  }

  createSalesContractDelivery(
    id: number,
    postModel: CreateContractDeliverySchema
  ): Promise<ContractDeliverySchema> {
    return this.post(`/trading/contracts/${id}/deliveries`, postModel)
  }

  editSalesContractDelivery(
    id: string,
    deliveryId: number,
    patchModel: PatchContractDeliverySchema
  ): Promise<ContractDeliverySchema> {
    return this.patch(`/trading/contracts/${id}/deliveries/${deliveryId}`, patchModel)
  }

  getSalesContracts(query?: Record<string, string>): Promise<ContractDetail> {
    const hasQuery = query && !!Object.values(query).length
    const queryParams = new URLSearchParams(query)

    return this.get(`/trading/contracts${hasQuery ? `?${queryParams.toString()}` : ''}`)
  }

  markDeliveryAsPaid(contractId: string, deliveryId: string, patchModel: Record<string, string>) {
    return this.patch(`/trading/contracts/${contractId}/deliveries/${deliveryId}/paid`, patchModel)
  }

  markDeliveriesRecorded(id: string, isRecorded: boolean): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/trading/contracts/${id}/${isRecorded ? 'all-recorded' : 'all-unrecorded'}`)
  }

  updateSalesContractFiles(contractId: string, putModel: PutContractFile): Promise<OpenAPIV3.ResponseObject> {
    return this.put(`/trading/contracts/${contractId}/files`, putModel)
  }

  deleteSalesContractFiles(contractId: string, fileId: string): Promise<ContractDetail> {
    return this.delete(`/trading/contracts/${contractId}/files/${fileId}`)
  }

  removeDelivery(contractId: string, deliveryId: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/trading/contracts/${contractId}/deliveries/${deliveryId}`)
  }

  rollOverSalesContract(contractId: string, postModel: ContractRollOverSchema): Promise<ContractDetail> {
    return this.post(`/trading/contracts/${contractId}/roll-over`, postModel)
  }

  patchContractPricing(contractId: string, patchModel: PatchContractPricingSchema): Promise<ContractDetail> {
    return this.patch(`/trading/contracts/${contractId}/pricing`, patchModel)
  }

  linkSalesContract(postModel: LinkContractSchema): Promise<OpenAPIV3.ResponseObject> {
    return this.post(`/trading/contracts/link`, postModel)
  }

  unlinkSalesContract(postModel: UnlinkContractSchema): Promise<OpenAPIV3.ResponseObject> {
    return this.post(`/trading/contracts/unlink-premium`, postModel)
  }
}
