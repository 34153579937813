import posthogJs from 'posthog-js'

const apiToken = process.env.POSTHOG_API_TOKEN
const apiHost = process.env.POSTHOG_API_HOST

const listMissingCredentials = () => {
  const missingCreds = Object.entries({
    POSTHOG_API_CLIENT: apiHost,
    POSTHOG_API_TOKEN: apiToken
  })
    .filter(([, value]) => !value)
    .map(([key]) => key)

  return missingCreds
}

if (!apiToken || !apiHost) {
  const missingCreds = listMissingCredentials()

  console.error(`Cannot initialise PostHog, missing API credentials ${missingCreds}`)
} else {
  posthogJs.init(apiToken, { api_host: apiHost, persistence: 'localStorage' })

  const initialFlagsJsonString = JSON.stringify(posthogJs.featureFlags.getFlags())

  posthogJs.onFeatureFlags(function () {
    const newFlagsJsonString = JSON.stringify(posthogJs.featureFlags.getFlags())

    if (initialFlagsJsonString !== newFlagsJsonString) {
      location.reload()
    }
  })

  posthogJs.reloadFeatureFlags()
}

export const identifyUser = (uid: string, email: string, organisation: string) =>
  posthogJs.identify(uid, { email, organisation, uid })

export const flagPayloads = posthogJs.featureFlags.getFlagPayloads()
