import { Document } from '@contentful/rich-text-types'

import { contentfulSpaces } from '~/lib/enums/contentful'
import { date, getISOString } from '~/lib/utility/date'

import { buildFetchOptions, fetchUrl, richTextToHTMLString } from './contentful'

export type FetchMarketInsightsNotificationsResponse = {
  data: {
    marketInsightsNotificationCollection: {
      items: Array<{
        sys: {
          publishedAt: string
          id: string
        }
        author: {
          displayName: string
          profilePicture: {
            url: string
          }
        }
        visibleUntil: string
        notificationBody: {
          json: Document
        }
      }>
    }
  }
}
export type MarketInsightsNotification =
  FetchMarketInsightsNotificationsResponse['data']['marketInsightsNotificationCollection']['items'][0] & {
    html: string
    hasViewed?: boolean
  }

export const fetchMarketInsightsNotifications = async ({
  fromDate
}: {
  fromDate?: string
}): Promise<MarketInsightsNotification[]> => {
  const now = getISOString(date())
  const query = `
      {
        marketInsightsNotificationCollection(
            order: sys_publishedAt_ASC
            where: {
              visibleUntil_gte: "${now}"
              ${fromDate ? `sys: { publishedAt_gt: "${fromDate}" }` : ''}
            }
        ) {
            items {
                sys {
                    publishedAt
                    id
                }
                author {
                    displayName
                    profilePicture {
                        url
                    }
                }
                visibleUntil
                notificationBody {
                    json
                }
            }
        }
      }
    `

  const fetchOptions = buildFetchOptions(query, contentfulSpaces.GRAINDEX)

  const response = await fetch(fetchUrl(contentfulSpaces.GRAINDEX), fetchOptions)
  const responseJson = (await response.json()) as FetchMarketInsightsNotificationsResponse

  return responseJson.data.marketInsightsNotificationCollection.items.map(notification => ({
    ...notification,
    html: richTextToHTMLString(notification.notificationBody.json)
  }))
}
