const middleware = {}

middleware['default'] = require('../src/middleware/default.js')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['inventory'] = require('../src/middleware/inventory.js')
middleware['inventory'] = middleware['inventory'].default || middleware['inventory']

middleware['logistics'] = require('../src/middleware/logistics.js')
middleware['logistics'] = middleware['logistics'].default || middleware['logistics']

export default middleware
