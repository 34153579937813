import { InsightsLivestockTaxonomySchema } from '@hectare/platform.clients.trading'

import { ApiInventory } from '~/lib/api-inventory'
import { InsightLivestockPriceDetail } from '~/lib/types/insight-livestock/livestock-price'
import { composeSearchParams, QueryOptions } from '~/lib/utility/url'

export class InsightLivestockService extends ApiInventory {
  fetchLivestockTaxonomy(): Promise<InsightsLivestockTaxonomySchema> {
    return this.get('trading/insights/livestock/taxonomy')
  }

  fetchLivestockPriceEstimate(queryParams: QueryOptions): Promise<InsightLivestockPriceDetail> {
    const urlParams = composeSearchParams(queryParams)

    return this.get(`trading/insights/livestock/price?${urlParams.toString()}`)
  }
}
