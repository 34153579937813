import { cloneDeep } from 'lodash'

import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    fetchMovements: new ApiModel(),
    updateScheduledAction: new ApiModel(),
    createInternalStoreMovement: new ApiModel(),
    deleteMovementApi: new ApiModel(),
    timelineMovementOpen: []
  }
}

export const state = () => initialState()

export const getters = {
  movements: state => {
    return cloneDeep(state.fetchMovements.response.data)
  },

  getTimelineMovementOpen: state => {
    return state.timelineMovementOpen
  }
}

export const actions = {
  fetchMovements({ getters }, forceUpdate = false) {
    if (forceUpdate || !getters.movements.length) {
      this.$api.movements(state.fetchMovements).useStorePath('inventory.movements.fetchMovements').getMovements()
    }
  },

  updateScheduledAction({ state }, { movementId, formModel }) {
    return this.$api
      .movements(state.fetchMovements)
      .useStorePath('inventory.movements.updateScheduledAction')
      .updateScheduledAction(movementId, formModel)
  },

  createInternalStoreMovement(_, newInternalMovement) {
    return this.$api
      .movements(state.createInternalStoreMovement)
      .useStorePath('inventory.movements.createInternalStoreMovement')
      .createInternalStoreMovement(newInternalMovement)
  },

  deleteMovement({ state }, movement) {
    return this.$api
      .movements(state.deleteMovementApi)
      .useStorePath('inventory.movements.deleteMovementApi')
      .deleteMovement({ recordId: movement.recordId, movementId: movement.id })
  }
}

export const mutations = {
  updateOpenTimelineMovement(state, payload) {
    state.timelineMovementOpen.push(payload)
  },

  emptyOpenTimelineMovement(state) {
    state.timelineMovementOpen.pop()
  }
}
