import { set as vueSet } from 'vue'

import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    storageLocationsApis: {
      store: new ApiModel(),
      field: new ApiModel()
    },
    fieldLocationApi: new ApiModel(),
    createStorageApi: new ApiModel(),
    bulkAdjustStorageBusinessUnitsApi: new ApiModel(),
    bulkAdjustStorageLocationGroupsApi: new ApiModel(),
    addCommodityToStorageApi: new ApiModel(),
    updateStorageApi: new ApiModel(),
    futureMovementsSubstorageApis: {},
    futureMovementsStorageApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  hasStorageLocations: (_state, getters) => storageType => {
    return !!getters.getStorageLocations[storageType].docs.length
  },

  getStorageLocations: state => storageType => {
    return {
      docs: state.storageLocationsApis[storageType]?.response?.data?.docs ?? [],
      isLoading: state.storageLocationsApis[storageType].isLoading
    }
  },

  getSubstoreFutureMovements: state => (storeId, subStorageId) => {
    return (
      state.futureMovementsSubstorageApis[`api-${storeId}-${subStorageId}`]?.response.data?.substorage?.[0]
        ?.movements ?? []
    )
  },

  getStorageFutureMovements: state => {
    const substoreFutureMovements = {}
    const storage = state.futureMovementsStorageApi?.response?.data?.substorage

    if (storage) {
      storage.forEach(substorage => {
        substoreFutureMovements[substorage.id] = substorage?.movements?.length ?? 0
      })
    }

    return substoreFutureMovements
  },

  getAvailableFields: state => {
    return state.fieldLocationApi.response.data?.docs ?? []
  }
}

export const mutations = {
  setState(state, value) {
    state.storage = value
  },

  setFutureMovementsSubstorageApi(state, { storageId, subStoreId, stateKey }) {
    if (!state.futureMovementsSubstorageApis[stateKey]) {
      vueSet(state.futureMovementsSubstorageApis, stateKey, new ApiModel())
    }
  }
}

export const actions = {
  setState({ commit }, value) {
    commit('setState', value)
  },

  createNewStorage({ state }, postModel) {
    return this.$api
      .storage(state.createStorageApi)
      .useStorePath('inventory.storage.createStorageApi')
      .createStorage(postModel)
  },

  updateStorage({ state }, { patchModel, storageId }) {
    return this.$api
      .storage(state.updateStorageApi)
      .useStorePath('inventory.storage.updateStorageApi')
      .updateStorage(storageId, patchModel)
  },

  async fetchAvailableFieldsByHarvestYear({ state }, harvestYear) {
    try {
      await this.$api
        .storage(state.fieldLocationApi)
        .useStorePath('inventory.storage.fieldLocationApi')
        .fetchAvailableFieldsByHarvestYear(harvestYear)
    } catch (error) {
      this.$log.error('fetching fields error', error)
    }
  },

  async fetchStorageLocations({ state }, { storageType, searchParams }) {
    try {
      await this.$api
        .storage(state.storageLocationsApis[storageType])
        .useStorePath(`inventory.storage.storageLocationsApis.${storageType}`)
        .fetchStorageLocations(storageType, searchParams)

      return state.storageLocationsApis[storageType]
    } catch (error) {
      this.$log.error('fetching storage error', error)
    }
  },

  async addCommodityToStorage({ state }, patchModel) {
    try {
      await this.$api
        .storage(state.addCommodityToStorageApi)
        .useStorePath('inventory.storage.addCommodityToStorage')
        .addCommodityToStorage(patchModel)
    } catch (error) {
      this.$log.error('Add commodity to storage error', error)
      throw error
    }
  },

  fetchSubstorageWithMovements({ state, commit }, { storageId, subStoreId }) {
    const stateKey = `api-${storageId}-${subStoreId}`

    commit('setFutureMovementsSubstorageApi', { storageId, subStoreId, stateKey })

    this.$api
      .storage(state.futureMovementsSubstorageApis[stateKey])
      .useStorePath(`inventory.storage.futureMovementsSubstorageApis.${stateKey}`)
      .fetchSubstorageWithMovements(storageId, subStoreId)
  },

  fetchStorageWithMovements({ state, commit }, storageId) {
    return this.$api
      .storage(state.futureMovementsStorageApi)
      .useStorePath('inventory.storage.futureMovementsStorageApi')
      .fetchStorageWithMovements(storageId)
  },

  async bulkAdjustStorageBusinessUnits({ state }, patchModel) {
    try {
      await this.$api
        .storage(state.bulkAdjustStorageBusinessUnitsApi)
        .useStorePath('inventory.storage.bulkAdjustStorageBusinessUnitsApi')
        .bulkAdjustStorageBusinessUnits(patchModel)
    } catch (error) {
      this.$log.error('Error in bulkAdjustStorageBusinessUnits', error)
      throw error
    }
  },

  async bulkAdjustStorageLocationGroups({ state }, patchModel) {
    try {
      await this.$api
        .storage(state.bulkAdjustStorageLocationGroupsApi)
        .useStorePath('inventory.storage.bulkAdjustStorageLocationGroupsApi')
        .bulkAdjustStorageBusinessUnits(patchModel)
    } catch (error) {
      this.$log.error('Error in bulkAdjustStorageLocationGroups', error)
      throw error
    }
  }
}
