import { InsightsLivestockTaxonomySchema } from '@hectare/platform.clients.trading'
import { ActionTree, GetterTree } from 'vuex'

import { ApiModel } from '~/plugins/api-logistics/api-model'

type Species = InsightsLivestockTaxonomySchema['species'][0]
interface Gender {
  id: number
  name: string
}

const initialState = () => {
  return {
    fetchTaxonomyApi: ApiModel<InsightsLivestockTaxonomySchema>()
  }
}

type State = ReturnType<typeof initialState>

export const state = () => initialState()

export const getters: GetterTree<State, State> = {
  getTaxonomySpecies: (state: State): InsightsLivestockTaxonomySchema['species'] => {
    return state.fetchTaxonomyApi.response.data?.species ?? []
  },

  getSpeciesOptions: (_state, getters) => {
    return getters.getTaxonomySpecies.map((species: Species, index: number) => {
      return {
        label: species.name,
        value: {
          id: species.id,
          name: species.name,
          taxonomyIndex: index,
          hasTypes: !!species.types?.length
        }
      }
    })
  },

  getSpeciesTypeOptionsByIndex: (_state, getters) => (taxonomyIndex: number) => {
    if (taxonomyIndex === undefined) return []

    return (
      getters.getTaxonomySpecies[taxonomyIndex]?.types?.map((type: { name: string; id: string }) => {
        return {
          label: type.name,
          value: type.id
        }
      }) ?? []
    )
  },

  getBreedOptionsByIndex: (_state, getters) => (taxonomyIndex: number) => {
    if (taxonomyIndex === undefined) return []

    return (
      getters.getTaxonomySpecies[taxonomyIndex]?.breeds?.map((breed: { name: string; id: string }) => {
        return {
          label: breed.name,
          value: breed.id
        }
      }) ?? []
    )
  },

  getGenderOptionsByIndex: (_state, getters) => (taxonomyIndex: number) => {
    if (taxonomyIndex === undefined) return []

    return getters.getTaxonomySpecies[taxonomyIndex]?.genders?.map((gender: Gender) => {
      return {
        label: gender.name,
        value: gender.id
      }
    })
  }
}

export const actions: ActionTree<State, State> = {
  fetchTaxonomies({ state, getters }, forceUpdate = false) {
    if (forceUpdate || !getters.getTaxonomySpecies.length) {
      return this.app.$api
        .insightLivestock(state.fetchTaxonomyApi)
        .useStorePath('insights-livestock.fetchTaxonomyApi')
        .fetchLivestockTaxonomy()
    }
  }
}
