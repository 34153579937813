import { CreateStorageNote, StorageNoteDetail } from '@hectare/platform.clients.inventory'

import { ApiInventory } from '~/lib/api-inventory'

export class StorageNotesService extends ApiInventory {
  createStorageNote(payload: CreateStorageNote): Promise<StorageNoteDetail> {
    return this.post('/inventory/storage-notes', payload)
  }

  getStorageNotes(query: { storageId: string; substorageId?: string }): Promise<StorageNoteDetail[]> {
    const queryParams = new URLSearchParams(query)

    return this.get(`/inventory/storage-notes?${query ? `&${queryParams.toString()}` : ''}`)
  }
}
