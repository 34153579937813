import { ApiInventory } from '~/lib/api-inventory'

export class AdmService extends ApiInventory {
  upload(file: File) {
    const requestBody = new FormData()

    requestBody.append('file', file)

    return this.post('/logistics/adm', requestBody)
  }

  download(dates?: { endDate: string; startDate: string }) {
    return this.get(
      `/logistics/adm${dates ? `?startDateISO=${dates.startDate}&endDateISO=${dates.endDate}` : ''}`,
      undefined,
      {
        responseType: 'text/csv'
      }
    )
  }
}
