import { createFetchHandler, system } from '@hectare/api-client'
import { capitalize } from 'lodash'
import Vue from 'vue'

import appVersion from '~/lib/app-version'
import { defaultBrandConfig } from '~/lib/enums/color.config'
import { getCountryConfig } from '~/lib/geo/country-config'
import { screens } from '~/lib/resolve-tailwind-config'
import { getOfflineStorage, setOfflineStorage } from '~/lib/utility/offline-storage'
import { unitConversions, WeightUnit } from '~/modules/inventory/lib/utility/unitTool'
import { deviceInfo, isNative, platform } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    coreConfig: {},
    inventoryBaseUrl: '',
    isClientLoaded: false,
    lastCoreConfigCountry: null,
    selectedFilterZone: 'REGION',
    hasClickedPostListing: Boolean(getOfflineStorage('hasClickedPostListing')) ?? false
  }
}

export const state = () => initialState()

export const getters = {
  getApp: (state, getters, rootState, rootGetters) => {
    const app = {}

    // GENERAL APP CONFIG
    app.config = {
      orgName: rootState.inventory.auth.profile?.organisation?.id,
      screens,
      colors: defaultBrandConfig.colors
    }
    app.platform = platform
    app.device = deviceInfo
    app.profile = rootState.inventory.auth.profile
    app.activeProfile = app.profile
    app.isStorybook = process.env.STORYBOOK === 'true'
    app.isProduction = process.env.NODE_ENV === 'production'
    app.isServer = !!process.server
    app.isClientLoaded = state.isClientLoaded
    app.version = appVersion.default
    app.isNative = isNative
    app.isMobileOrNative = isNative || Vue.prototype.$screen.width <= 768
    app.isUsingTouch = process.client && window.matchMedia('(any-pointer: coarse)').matches
    const countryISO = app.activeProfile?.organisation?.countryISO ?? 'GB'

    app.currentOrganisation = app.activeProfile?.organisation ?? {}

    app.currentOrganisation.country = {
      iso2: countryISO,
      currency: getters.getCurrentOrgCountryInfo?.currency ?? 'GBP'
    }

    app.countryConfig = getCountryConfig(app.currentOrganisation?.country?.iso2)

    app.deviceDetails = rootState.device.details[isNative ? 'native' : 'web']
    app.countryCode = countryISO
    app.localUnits = getters.getCurrentOrgCountryInfo?.units
    app.isInventory = true
    app.isReady = true // TODO: Update to check if it's in maintenance mode
    app.isMaintenanceModeEnabled = false
    app.isMaintenanceModeBypassed = false
    app.isOnline = rootGetters['device/isOnline']
    app.isAuth = rootGetters['inventory/auth/hasTokens'] && !!rootState.inventory?.auth.profile?.givenName
    app.isMobileMenuOpen = getters.getIsMobileMenuOpen
    app.businessUnits = app.profile?.organisation?.businessUnits ?? []
    app[`is${capitalize(process.env.APP_ENVIRONMENT)}`] = true
    app[`is${capitalize(platform)}`] = true

    return app
  },

  getCurrentOrgCountryInfo: (state, _getters, rootState) => {
    const countryISO = rootState.inventory?.auth?.profile?.organisation?.countryISO ?? 'GB'
    const countryDetails = state.coreConfig.countries?.[countryISO]

    if (!countryDetails) {
      return { iso: 'GB', iso2: 'GB' }
    }

    return {
      ...countryDetails,
      iso2: countryDetails.iso
    }
  },

  getCommodities: state => {
    return state.coreConfig.commodities
  },

  getCommodityDetails: (state, getters) => (commodity, inventoryType) => {
    const matchedCommoditiesByInventoryType =
      getters.getCommodities.find(commodity => commodity.id === inventoryType.toLowerCase())?.items ?? []

    const matchedCommodity = matchedCommoditiesByInventoryType.find(
      storeCommodity => storeCommodity.id === commodity.id
    )

    if (!matchedCommodity) {
      return
    }

    const commodityType = matchedCommodity.types.find(type => type.id === commodity.type)
    const commodityGrade = commodityType?.grades.find(grade => grade.id === commodity.grade)
    const commodityTags = matchedCommodity.tags
      .filter(tag => {
        return commodity.tags.find(inventoryTag => inventoryTag === tag.id)
      })
      .map(tag => tag.name)
      .join(', ')

    return {
      commodity: matchedCommodity,
      type: commodityType,
      grade: commodityGrade,
      tags: commodityTags,
      summary: [matchedCommodity?.name, commodityType?.name, commodityGrade?.name, commodityTags]
        .filter(value => !!value && value !== '-')
        .join(', ')
    }
  },

  hasCommodities: state => {
    return !!state.coreConfig?.commodities?.length ?? 0
  },

  getCombinedCommodities: (state, getters) => {
    if (getters.getCommodities) {
      return getters.getCommodities.flatMap(commodity => {
        return commodity.items.map(items => {
          return { ...items, type: commodity.type }
        })
      })
    }

    return []
  },

  getCommodityWeightName: state => (commodityId, inventoryType) => {
    // Find the group of commodities from the commodity type
    const commodityGroup = state.coreConfig.commodities
      ? state.coreConfig.commodities.find(commodities => commodities.type === inventoryType.toLowerCase())
          ?.items ?? []
      : []

    // Get hold of the name of the weight based off the commodity they have
    // selected
    const weightName = commodityGroup.find(commodity => commodity.id === commodityId)?.weight?.name

    return weightName || 'tonne'
  },

  getCountries: state => {
    return state.coreConfig.countries ?? {}
  },

  getRegionsForCurrentCountry: state => {
    return state.coreConfig.regions ?? []
  },

  getFullCountries: state => {
    return state.coreConfig?.countries
      ? Object.keys(state.coreConfig?.countries).map(key => ({
          ...state.coreConfig?.countries[key]
        }))
      : {}
  },

  getAdjustmentReasons: state => {
    return state.coreConfig?.inventory?.adjustmentReasons ?? []
  },

  getStorageNoteReasons: state => {
    return state.coreConfig?.inventory?.storageNoteReasons ?? []
  },

  getAdjustReasonsByStorageType: (state, getters) => storageType => {
    return getters.getAdjustmentReasons.filter(reason => reason.type === storageType)
  },

  getWeighbridgeUnit: state => {
    return state.coreConfig.commodities[0]?.weighbridgeWeight
  },

  getCommodityDetailById: (state, getters) => commodityId => {
    return getters.getCombinedCommodities?.find(item => {
      return item.id === commodityId
    })
  },

  getCommodityInventoryTypeById: (state, getters) => commodityId => {
    return getters.getCommodityDetailById(commodityId)?.type
  },

  getCommodityCategoryByCommodityId: (state, getters) => commodityId => {
    const commoditiesCoreConfig = getters.getCommodities

    for (const [index, commodityCore] of commoditiesCoreConfig.entries()) {
      const foundCommodity = commodityCore.items.find(commodity => commodity.id === commodityId)

      if (foundCommodity) {
        return commoditiesCoreConfig[index].id
      }
    }
  },

  getKgConversionFactor: state => {
    return state.coreConfig.conversions
  },

  getWeightConfigByCommodityId: (state, getters) => commodityId => {
    const commodityType = getters.getCommodityInventoryTypeById(commodityId)
    const commoditiesToSearch = state.coreConfig?.commodities?.find(item => item.type === commodityType)?.items

    const commodity = commoditiesToSearch?.find(item => item.id === commodityId)

    if (commodity) {
      return commodity.weight
    }

    // Hard fallback to tonne if somehow the commodity is missing
    return {
      name: WeightUnit.tonne,
      conversion: unitConversions.weight[WeightUnit.tonne]
    }
  },

  getUserCurrency: (state, getters, rootState, rootGetters) => {
    const getCurrentOrgCountryInfo = getters.getCurrentOrgCountryInfo

    const currency = rootGetters['geo/getCurrencyOptions'].find(currency => {
      return currency.value === getCurrentOrgCountryInfo?.currency
    })

    return currency ?? { label: '£', value: 'GBP' }
  },

  getIsMobileMenuOpen(_state, _getters, _rootState, rootGetters) {
    return rootGetters['modal/activeModal'].modalName === 'inventory/mobile-menu'
  },

  getSettlementDeductionReasons: state => {
    return state.coreConfig?.trading?.settlementDeductionReasons ?? []
  },

  getSettlementBonusReasons: state => {
    return state.coreConfig?.trading?.settlementBonusReasons ?? []
  },

  getSelectedFilterZone: state => {
    return state.selectedFilterZone ?? 'REGION'
  },

  getHasClickedPostListing: state => {
    return state.hasClickedPostListing
  }
}

export const actions = {
  async fetchCoreConfig({ state, commit, dispatch, rootState }, { countryCode, forceUpdate = false }) {
    const accessToken = rootState.inventory.auth.accessToken
    const idToken = rootState.inventory.auth.idToken

    const fetchHandler = createFetchHandler({
      apiUrl: this.app.$config.apiBaseUrlInventory,
      accessToken,
      idToken
    })

    const hasCountryChanged = state.lastCoreConfigCountry !== countryCode

    if (!Object.keys(state.coreConfig).length || forceUpdate || hasCountryChanged) {
      const response = await system.initialise.getCoreConfig(fetchHandler, countryCode)

      commit('setLastCoreConfigCountry', countryCode)
      commit('setCoreConfig', { ...response.data, regions: { [countryCode]: response.data.regions } })

      await dispatch('inventory/record/fetchInventoryRecords', { forceUpdate }, { root: true })
    }
  }
}

export const mutations = {
  setClientLoaded(state) {
    state.isClientLoaded = true
  },

  setHasFetchedInitialStatus(state, hasFetchedInitialStatus) {
    state.hasFetchedInitialStatus = hasFetchedInitialStatus
  },

  setCoreConfig(state, coreConfig) {
    state.coreConfig = coreConfig
  },

  setInventoryBaseUrl(state, inventoryBaseUrl) {
    state.inventoryBaseUrl = inventoryBaseUrl
  },

  setLastCoreConfigCountry(state, countryCode) {
    state.lastCoreConfigCountry = countryCode
  },

  setSelectedFilterZone(state, zone) {
    state.selectedFilterZone = zone
  },

  setHasClickedPostListing(state) {
    setOfflineStorage('hasClickedPostListing', 'true')

    state.hasClickedPostListing = Boolean(getOfflineStorage('hasClickedPostListing'))
  }
}
