import { ApiInventory } from '~/lib/api-inventory'

export class TradePosition extends ApiInventory {
  fetchTradePositionCommodities() {
    return this.get(`/trading/contracts?docs=false&format=commodities`)
  }

  getTradingConfig() {
    return this.get('/trading/configuration')
  }
}
