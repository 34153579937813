import {
  BusinessConnectionsSummary,
  CreateBusinessConnection,
  OrganisationDetail,
  OrganisationExists,
  PatchOrganisation,
  UserDetail
} from '@hectare/platform.clients.customers'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'
import { composeSearchParams, QueryOptions } from '~/lib/utility/url'

export class BusinessConnectionsService extends ApiInventory {
  fetchBusinessConnections(queryParams: QueryOptions): Promise<BusinessConnectionsSummary> {
    const urlParams = composeSearchParams(queryParams)

    return this.get(`/customers/business-connections${urlParams ? `?${urlParams}` : ''}`)
  }

  checkBusinessExists(postModel: CreateBusinessConnection): Promise<OrganisationExists> {
    return this.post(`/customers/business-connections/exists`, postModel)
  }

  connectBusiness(postModel: CreateBusinessConnection): Promise<BusinessConnectionsSummary> {
    return this.post(`/customers/business-connections`, postModel)
  }

  updateBusinessConnection(id: string, postModel: PatchOrganisation): Promise<OrganisationDetail> {
    return this.patch(`/customers/organisations/${id}`, postModel)
  }

  deleteBusinessConnection(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`customers/business-connections/${id}`)
  }

  connectContact(orgId: string, userId: string): Promise<UserDetail> {
    return this.post(`/customers/business-connections/${orgId}/contact/${userId}/connect`)
  }

  disconnectContact(orgId: string, userId: string): Promise<UserDetail> {
    return this.post(`/customers/business-connections/${orgId}/contact/${userId}/disconnect`)
  }

  deleteBusinessConnectionContact(id: string, userId: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`customers/business-connections/${id}/contact/${userId}`)
  }
}
