import { cloneDeep, isEmpty } from 'lodash'
import { set as vueSet } from 'vue'

import { searchFilterEnumConfig as filterCookies } from '~/lib/enums/cookies/cookie-keys'
import { initialSearchState, stateConfig } from '~/lib/search/search-initial-state'
import { filterSearchURLParams, formatApiPath } from '~/lib/search/utility/search'
import { getOfflineStorage, setOfflineStorage } from '~/lib/utility/offline-storage'
import { filterConfig } from '~/modules/inventory/lib/enums/filters'
import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    searchApi: new ApiModel(),
    isFirstSearch: true,
    ...initialSearchState
  }
}

export const state = () => initialState()

export const getters = {
  hasActiveFilters: state => stateKey => {
    const filters = Object.keys(state.activeFilters[stateKey])

    if (!filters.length) {
      return false
    }

    return filters.some(filter => state.activeFilters[stateKey][filter].length)
  },

  getAvailableFilters: state => stateKey => {
    return state.availableFilters[stateKey] ?? []
  },

  getActiveFilters: state => stateKey => {
    return state.activeFilters[stateKey] ?? {}
  },

  getFieldFilter: (state, getters) => (stateKey, field) => {
    return state.activeFilters[stateKey][field] ?? []
  },

  getActiveFilterTags:
    (state, getters) =>
    (stateKey, blackListedTags = []) => {
      // these will be the tags that have been selected
      const availableFilters = cloneDeep(getters.getActiveFilters(stateKey))

      let activeFilterKeys = Object.keys(availableFilters)

      // Do not show the blacklisted tags
      activeFilterKeys = activeFilterKeys.filter(tag => !blackListedTags.includes(tag))

      return activeFilterKeys.flatMap(filterKey => {
        const terms = getters.getFieldFilter(stateKey, filterKey).terms ?? []

        return terms.map(term => {
          return { field: filterKey, value: term }
        })
      })
    },

  getReducedFilterConfig: () => {
    // Give us an array with the first item being the object key (i.e. RECORD_TYPE)
    // and the second item with the facetKey to make it easier to loop over
    return Object.entries(filterConfig).map(config => {
      return { label: config[0], name: config[1].facetKey }
    })
  },

  getSearchResults: state => stateKey => {
    return {
      docs: state.docs[stateKey] ?? [],
      aggregations: state.aggregations[stateKey] ?? [],
      totalDocs: state.totalDocs[stateKey] ?? [],
      totalYield: state.totalYield[stateKey] ?? {},
      custom: state.custom[stateKey] ?? {},
      limit: state.searchLimit[stateKey] ?? 25,
      offset: state.searchOffset ?? 0
    }
  },

  getAggregations: state => stateKey => {
    return state.aggregations[stateKey] ?? []
  },

  getFacets: state => stateKey => {
    return state.facets[stateKey] ?? []
  },

  isLoading: state => {
    return state.searchApi.isLoading
  },

  getLastCalledApiPath: state => stateKey => {
    return state.lastCalledApiPath[stateKey]
  }
}

export const mutations = {
  updateSearchLimit(state, { limit, stateKey }) {
    state.searchLimit[stateKey] = limit
  },

  updateSearchOffset(state, offset) {
    state.searchOffset = offset
  },

  setLastCalledApiPath(state, { lastCalledApiPath, stateKey }) {
    state.lastCalledApiPath[stateKey] = lastCalledApiPath
  },

  setAvailableFilters(state, { stateKey, filters }) {
    state.availableFilters[stateKey] = filters
  },

  clearFilters(state, stateKey) {
    if (state.activeFilters[stateKey]) {
      const filterKeys = Object.keys(state.activeFilters[stateKey])

      filterKeys.forEach(key => {
        state.activeFilters[stateKey][key] = []
      })
    }
  },

  setSearchState(state, data) {
    const stateKey = data.stateKey

    Object.entries(data)
      .filter(item => item[0] !== 'stateKey')
      .forEach(item => {
        const label = item[0]
        const value = item[1]

        vueSet(state[label], stateKey, value)
      })
  },

  // Set whole scope of filter arrays at once
  setActiveFilters(state, { stateKey, filters }) {
    // mapping FE config to API returned values
    const reducedFilterConfig = getters.getReducedFilterConfig(stateKey)

    const activeFilters = {}

    if (filters.length) {
      filters.forEach(filter => {
        reducedFilterConfig.forEach(format => {
          // checking the facet key against the filters.js enums and getting hold of the
          // field name on FE (i.e checking f:type to match to RECORD_TYPE)
          if (filter.name === format.name) {
            return (activeFilters[format.label] = filter)
          }
        })
      })
    }

    vueSet(state.activeFilters, stateKey, activeFilters)
  },

  // Set a single filter array
  setFieldFilters(state, { stateKey, field, value }) {
    // Reduce filter config to make checking values easier
    const reducedFilterConfig = getters.getReducedFilterConfig(stateKey)

    if (!state.activeFilters[stateKey][field]?.terms) {
      // Sets up the format of the state based off filters.js enums
      reducedFilterConfig.forEach(format => {
        if (format.label === field) {
          return (state.activeFilters[stateKey][format.label] = {
            name: format.name,
            clearUrl: value.url,
            terms: [value]
          })
        }
      })
    } else {
      // Loop through active filter terms and toggle
      state.activeFilters[stateKey][field].terms.forEach(term => {
        if (term.id === value.id) {
          // Removes the option if its already in state
          state.activeFilters[stateKey][field].terms = state.activeFilters[stateKey][field].terms.filter(
            term => term.id !== value.id
          )
        } else {
          // Adds the option to state
          state.activeFilters[stateKey][field].terms.push(value)
        }
      })
    }
  },

  setIsFirstSearch(state, value) {
    state.isFirstSearch = value
  }
}

export const actions = {
  updateSearchParams({ commit, dispatch, state }, { limit = 25, offset, stateKey, additionalSearchParams }) {
    commit('updateSearchLimit', { limit, stateKey })
    commit('updateSearchOffset', offset)

    dispatch('search', {
      apiPath: state.lastCalledApiPath[stateKey],
      stateKey,
      additionalSearchParams
    })
  },

  updateFieldFilter(
    { commit, dispatch },
    { stateKey, field, value, shouldClearFilter = false, shouldReturnDocs = true, localFilterConfig = {} }
  ) {
    const canMultiSelect = localFilterConfig[field]?.canMultiSelect ?? filterConfig[field].canMultiSelect

    let apiPath = value.url

    if (shouldClearFilter && value.clearUrl) {
      // Used by filterTagBar.vue to remove preselected options
      apiPath = value.clearUrl
    } else if (!canMultiSelect) {
      apiPath = value.selected ? value.url : value.replaceUrl
    }

    commit('setFieldFilters', { stateKey, field, value })
    dispatch('search', { apiPath, stateKey, shouldReturnDocs })
  },

  clearFilters({ commit, dispatch, state }, stateKey) {
    commit('clearFilters', stateKey)

    dispatch('search', { apiPath: state.clearUrl[stateKey], stateKey })
  },

  async search(
    { dispatch, state, commit },
    {
      apiPath = null,
      stateKey,
      shouldReturnDocs = true,
      additionalSearchParams = {},
      forceUseSavedPath = false,
      shouldUseCookiePath = true,
      customQueryParams = {}
    }
  ) {
    const currentQueryParams =
      Object.keys(customQueryParams).length > 0 ? customQueryParams : this.$router.currentRoute.query
    const isFirstSearch = state.isFirstSearch

    let searchOptions = {}

    const { searchParams, otherParams } = filterSearchURLParams(currentQueryParams)

    // Deep links - user's first load should use params already in url
    if (isFirstSearch && !isEmpty(searchParams)) {
      // Keep searchFilterEnums up to date with query keys
      searchOptions = { ...searchParams, ...stateConfig[stateKey]?.defaultSearchParams }
    } else {
      searchOptions = {
        ...stateConfig[stateKey]?.defaultSearchParams,
        offset: additionalSearchParams.offset ?? state.searchOffset,
        limit: additionalSearchParams.limit ?? state.searchLimit[stateKey],
        ...additionalSearchParams
      }
    }

    let formattedApiPath = apiPath

    // Get saved api path if required

    const storedSearchPath = getOfflineStorage(filterCookies[stateKey])

    if (
      (shouldUseCookiePath && !!storedSearchPath && apiPath === null) ||
      (!!storedSearchPath && forceUseSavedPath)
    ) {
      formattedApiPath = storedSearchPath
    }

    formattedApiPath = formatApiPath(formattedApiPath, shouldReturnDocs)

    try {
      const searchApiModel = await this.$api
        .search(state.searchApi)
        .useStorePath('inventory.search.searchApi')
        .search(formattedApiPath, stateKey, searchOptions)

      commit('setLastCalledApiPath', { lastCalledApiPath: searchApiModel.response.path, stateKey })

      // set state for relevant store
      dispatch('setStateForStore', { stateKey, shouldReturnDocs })

      // Used for the available options on a dropdown
      commit('setAvailableFilters', { stateKey, filters: state.searchApi.response.data.facets ?? [] })

      // Updates with the active filters returned from the API
      commit('setActiveFilters', { stateKey, filters: state.searchApi.response.data.activeFilters ?? [] })

      // set the last used path to the cookie

      if (filterCookies[stateKey]) {
        setOfflineStorage(filterCookies[stateKey], formattedApiPath)
      }

      const newQuery = { ...searchApiModel.response.params, ...otherParams }

      if (JSON.stringify(this.$router.currentRoute.query) !== JSON.stringify(newQuery)) {
        this.$router.replace({
          query: newQuery
        })
      }

      commit('setIsFirstSearch', false)
    } catch (error) {
      if (state.searchApi.response.code === 404) {
        commit('setLastCalledApiPath', { lastCalledApiPath: null, stateKey })

        if (!window.location.href.includes('isRetry=true')) {
          window.location.href = `${window.location.pathname}?isRetry=true`
        }
      }

      this.$log.debug('Error in search', error)
    }
  },

  setStateForStore({ state, commit }, { stateKey, shouldReturnDocs }) {
    let data = {
      stateKey,
      clearUrl: state.searchApi.response?.data.clearUrl ?? '',
      custom: state.searchApi.response?.data?.custom ?? {}
    }

    if (shouldReturnDocs) {
      data = {
        ...data,
        docs: state.searchApi.response?.data?.docs ?? [],
        totalDocs: state.searchApi.response?.data?.totalDocs ?? 0,
        aggregations: state.searchApi.response?.data?.aggregations ?? [],
        totalYield: state.searchApi.response?.data?.totalYield ?? {},
        custom: state.searchApi.response?.data?.custom ?? {},
        facets: state.searchApi.response?.data?.facets ?? []
      }
    }

    commit('setSearchState', data)
  },

  fetchSearchResults({ dispatch }, { stateKey, searchQuery, additionalSearchParams }) {
    if (searchQuery) {
      additionalSearchParams = { ...additionalSearchParams, query: searchQuery }
    }

    dispatch('search', {
      stateKey,
      additionalSearchParams
    })
  }
}
