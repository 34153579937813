import {
  ChangeOwner,
  OrganisationDetail,
  OrganisationSummary,
  PatchOrganisation,
  PatchOrganisationAdvancePay,
  UserDetail
} from '@hectare/platform.clients.customers'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'
import { ApiModelType } from '~/plugins/api-logistics/api-model'

export class OrganisationService extends ApiInventory {
  async fetchOrganisations({ limit = 25, offset = 0, query = '' }): Promise<OrganisationDetail[]> {
    try {
      return await this.get(`/customers/organisations?offset=${offset}&limit=${limit}&query=${query}`)
    } catch (error) {
      this.nuxt.$log.error(
        `Error calling /customers/organisations?offset=${offset}&limit=${limit}&query=${query}`,
        error
      )
      throw error
    }
  }

  async fetchOrganisationById(orgId: string): Promise<ApiModelType<OrganisationDetail>> {
    try {
      return await this.get(`/customers/organisations/${orgId}`)
    } catch (error) {
      this.nuxt.$log.error(`Error calling GET /customers/organisations/${orgId}`, error)
      throw error
    }
  }

  async fetchOrganisationsByIds(orgIds: string[]): Promise<ApiModelType<OrganisationSummary[]>> {
    try {
      return await this.get(`/customers/organisations/byid?ids=${orgIds.join(',')}`)
    } catch (error) {
      this.nuxt.$log.error(`Error calling GET /customers/organisations/byid?ids=${orgIds.join(',')}`, error)
      throw error
    }
  }

  async editOrganisation(orgId: string, postModel: PatchOrganisation) {
    try {
      return await this.patch(`/customers/organisations/${orgId}`, postModel)
    } catch (error) {
      this.nuxt.$log.error(`Error calling PATCH /customers/organisations/${orgId}`, error)
      throw error
    }
  }

  getTeamMembers({ limit = 25, offset = 0 }): Promise<UserDetail[]> {
    return this.get(`/customers/users?offset=${offset}&limit=${limit}`)
  }

  changeUserToOwner(postModel: ChangeOwner): Promise<OrganisationDetail> {
    return this.patch(`/customers/organisations/owner`, postModel)
  }

  unlockModule(organisationId: string, moduleId: string): Promise<OrganisationDetail> {
    return this.post(`/customers/organisations/${organisationId}/modules/unlock`, { id: moduleId })
  }

  patchAdvancePayDetails(
    orgId: string,
    postModel: PatchOrganisationAdvancePay
  ): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/customers/organisations/${orgId}/advance-pay`, postModel)
  }

  // Admin only
  impersonateOrganisation(organisationId: string): Promise<{ token: string }> {
    return this.get(`/customers/organisations/${organisationId}/impersonate`)
  }
}
