import { ApiInventory } from '~/lib/api-inventory'

export class StorageService extends ApiInventory {
  getStorage(id) {
    return this.get(`/inventory/storage/${id}`)
  }

  fetchStorageWithMovements(storageId) {
    return this.get(`/inventory/storage/${storageId}/substorage`)
  }

  fetchSubstorageWithMovements(storageId, subStoreId) {
    return this.get(`/inventory/storage/${storageId}/substorage?substorageId=${subStoreId}`)
  }

  async createStorage(postModel) {
    try {
      return await this.post('/inventory/storage', postModel)
    } catch (error) {
      this.nuxt.$log.error('Error creating storage:', error)
      throw new Error(error)
    }
  }

  async updateStorage(storageId, patchModel) {
    try {
      return await this.patch(`/inventory/storage/${storageId}`, patchModel)
    } catch (error) {
      this.nuxt.$log.error('Error updating storage:', error)
      throw new Error(error)
    }
  }

  fetchAvailableFieldsByHarvestYear(harvestYear) {
    const limit = 200

    return this.get(`/inventory/storage/list?type=field&harvestYear=${harvestYear}&limit=${limit}`)
  }

  fetchStorageLocations(storageType, searchParams = {}, businessUnitIds = []) {
    const params = new URLSearchParams({
      ...(storageType !== 'all' ? { type: storageType } : {}),
      ...(businessUnitIds && businessUnitIds.length ? { businessUnitIds: businessUnitIds.join(',') } : {}),
      ...searchParams
    })

    const path = `/inventory/storage/list${params.toString() ? `?${params.toString()}` : ''}`

    return this.get(path)
  }

  deleteStorage(id) {
    return this.delete(`/inventory/storage/${id}`)
  }

  deleteSubstorage(parentId, id) {
    return this.delete(`/inventory/storage/${parentId}/substorage/${id}`)
  }

  addCommodityToStorage(patchModel) {
    return this.patch(`/inventory/storage/${patchModel.storageId}/storable-commodity`, patchModel.commodity)
  }

  bulkAdjustStorageBusinessUnits(patchModel) {
    return this.patch(`/inventory/storage/businessunits`, patchModel)
  }

  bulkAdjustStorageLocationGroups(patchModel) {
    return this.patch(`/inventory/storage/locationgroups`, patchModel)
  }
}
