import {
  CreateMovementInternal,
  CreateMovementOutbound,
  EditMovementInternal,
  EditMovementOutbound,
  MovementDetails,
  PatchScheduledMovementActions
} from '@hectare/platform.clients.inventory'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'

interface DuplicateMovementParams {
  movementId: string | number
  duplicateCopies: number
  recordId: string
}

export class MovementsService extends ApiInventory {
  getMovements(query: Record<string, string>): Promise<MovementDetails> {
    const queryParams = new URLSearchParams(query)

    return this.get(`/inventory/movements?${query ? `&${queryParams.toString()}` : ''}`)
  }

  getMovementsExpired(searchParams: unknown): Promise<MovementDetails> {
    return this.get(`/inventory/movements/expired${searchParams ? `&${searchParams}` : ''}`)
  }

  createInternalStoreMovement(newInternalMovement: CreateMovementInternal): Promise<OpenAPIV3.ResponseObject> {
    return this.post('/inventory/movements/internal', newInternalMovement)
  }

  updateInternalMovement(internalMovement: {
    movementId: string
    formModel: EditMovementInternal
  }): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/inventory/movements/${internalMovement.movementId}/internal`, internalMovement.formModel)
  }

  deleteMovement({
    recordId,
    movementId
  }: {
    recordId: string
    movementId: string
  }): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/inventory/movements/${movementId}`, {
      recordId
    })
  }

  updateScheduledAction(
    movementId: string,
    postModel: PatchScheduledMovementActions
  ): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/inventory/movements/${movementId}/actions`, postModel)
  }

  createExternalMovement(payload: CreateMovementOutbound): Promise<OpenAPIV3.ResponseObject> {
    return this.post('/inventory/movements/outbound', payload)
  }

  updateExternalMovement(externalMovement: {
    movementId: string
    formModel: EditMovementOutbound
  }): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/inventory/movements/${externalMovement.movementId}/outbound`, externalMovement.formModel)
  }

  createDuplicateMovement({ movementId, duplicateCopies, recordId }: DuplicateMovementParams) {
    return this.post(`/inventory/movements/${movementId}/duplicate`, {
      recordId: `records/${recordId}`,
      count: duplicateCopies
    })
  }
}
