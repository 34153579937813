import { ApiInventory } from '~/lib/api-inventory'

export class AccountService extends ApiInventory {
  async getAccountProfile() {
    return await this.get(`/inventory-account/v1/profile`)
  }

  async getAccountBusiness() {
    return await this.get(`/inventory-account/v1/business`)
  }
}
