import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    fetchInventoryRecord: new ApiModel(),
    fetchInventoryRecords: new ApiModel(),
    inventoryRecords: {},
    sampleRecord: new ApiModel(),
    varietiesApi: new ApiModel(),
    timelineActivityTypesApi: new ApiModel(),
    commoditiesApi: new ApiModel(),
    createInventoryApi: new ApiModel(),
    fetchInventoryRecordsById: new ApiModel(),
    createAdjustment: new ApiModel(),
    editAdjustmentFile: new ApiModel(),
    deleteAdjustmentFile: new ApiModel(),
    commoditySpecificationsApi: new ApiModel(),
    commoditiesByOrganisationApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  inventoryRecord: state => {
    const recordData = state.fetchInventoryRecord.response.data

    return Array.isArray(recordData) ? {} : recordData
  },

  inventoryRecordsById: state => {
    return state.fetchInventoryRecordsById.response.data
  },

  getRecordsInField: (state, getters, rootState) => {
    return {
      docs: rootState.inventory.search.docs.recordsInField ?? [],
      totalDocs: rootState.inventory.search.totalDocs.recordsInField ?? []
    }
  },

  commodities: (state, getters, rootState) => {
    return rootState.inventory.app.coreConfig.commodities ?? []
  },

  varieties: state => {
    return state.varietiesApi.response.data ?? []
  },

  commodityByTypes: (state, getters) => type => {
    return getters?.commodities.filter(commodity => commodity.type === type) ?? []
  },

  commodityById: (state, getters) => commodityId => {
    const matchedCommomdity = getters.commodities.find(commodity => {
      return commodity.items.find(item => item.id === commodityId)
    })

    return {
      type: matchedCommomdity?.type ?? undefined,
      commodity: matchedCommomdity?.items.find(commodity => commodity.id === commodityId)
    }
  },

  getCommodityListByInventoryType: (state, getters) => inventoryType => {
    if (!inventoryType) return []

    const commoditiesByType = getters.commodityByTypes(inventoryType.toLowerCase())
    const options = commoditiesByType.map(commodity => commodity.items)[0]

    return options ?? []
  },

  getCommoditySpecificationTypes: state => {
    return state.commoditySpecificationsApi.response.data ?? []
  },

  getCommoditiesByOrganisation: state => {
    return state.commoditiesByOrganisationApi.response.data?.commodities ?? []
  },

  getHarvestYears: state => {
    return state.commoditiesByOrganisationApi.response.data?.harvestYears ?? []
  },

  hasInventoryRecords: state => {
    return state.fetchInventoryRecords.response.data?.totalDocs > 0
  }
}

export const actions = {
  async fetchInventoryRecord({ getters }, id, forceUpdate = false) {
    if (forceUpdate || !getters.inventoryRecord.length) {
      await this.$api
        .record(state.fetchInventoryRecord)
        .useStorePath('inventory.record.fetchInventoryRecord')
        .getInventoryRecord(id)
    }
  },

  async fetchInventoryRecords({ getters }, forceUpdate = false) {
    if (forceUpdate || !getters.inventoryRecord.length) {
      await this.$api
        .record(state.fetchInventoryRecords)
        .useStorePath('inventory.record.fetchInventoryRecords')
        .getInventoryRecordByFilters()
    }
  },

  async fetchCommodities({ getters }, forceUpdate = false) {
    if (forceUpdate || !getters.commodities.length) {
      try {
        await this.$api
          .record(state.commoditiesApi)
          .useStorePath('inventory.record.commoditiesApi')
          .getCommodities()
      } catch (error) {
        this.$log.error('Fetch commodities error', error)
      }
    }
  },

  async createInventory({ state }, postModel) {
    try {
      await this.$api
        .record(state.createInventoryApi)
        .useStorePath('inventory.record.createInventoryApi')
        .createInventory(postModel)

      this.$log.debug('Create new inventory response', state.createInventoryApi.response.data)
      return state.createInventoryApi.response
    } catch (error) {
      this.$log.error('Error setting inventory', error)
      return state.createInventoryApi.response
    }
  },

  async fetchInventoryRecordsById({ getters }, ids) {
    try {
      await this.$api
        .record(state.fetchInventoryRecordsById)
        .useStorePath('inventory.record.fetchInventoryRecordsById')
        .getInventoryRecordsById(ids)
    } catch (error) {
      this.$log.error('Error fetching records by id', error)
    }
  },

  async createSampleRecord({ state }, { postModel, id }) {
    try {
      await this.$api
        .record(state.sampleRecord)
        .useStorePath('inventory.record.sampleRecord')
        .createSampleRecord({ postModel, id })
    } catch (error) {
      this.$log.error('Error creating sample record', error)
      throw error
    }
  },

  async createAdjustment({ state }, postModel) {
    try {
      await this.$api
        .record(state.createAdjustment)
        .useStorePath('inventory.record.createAdjustment')
        .createAdjustment(postModel)
    } catch (error) {
      this.$log.error('Error creating record adjustment', error)
    }
  },

  async editAdjustmentFile({ state }, { model, recordId, adjustmentId }) {
    try {
      await this.$api
        .record(state.editAdjustmentFile)
        .useStorePath('inventory.record.editAdjustmentFile')
        .editAdjustmentFile(model, recordId, adjustmentId)
    } catch (error) {
      this.$log.error('Error updating record adjustment file', error)
    }
  },

  async deleteAdjustmentFile({ state }, { recordId, adjustmentId, fileId }) {
    try {
      await this.$api
        .record(state.deleteAdjustmentFile)
        .useStorePath('inventory.record.deleteAdjustmentFile')
        .deleteAdjustmentFile(recordId, adjustmentId, fileId)
    } catch (error) {
      this.$log.error('Error deleting record adjustment file', error)
    }
  },

  async fetchSpecificationsByType({ state }, type) {
    try {
      await this.$api
        .record(state.commoditySpecificationsApi)
        .useStorePath('inventory.record.commoditySpecificationsApi')
        .getCommoditySpecifications(type)
    } catch (error) {
      this.$log.error('Fetch commodity specifications error', error)
    }
  },

  async fetchCommoditiesByOrganisation({ state }) {
    try {
      await this.$api
        .record(state.commoditiesByOrganisationApi)
        .useStorePath('inventory.record.commoditiesByOrganisationApi')
        .getCommoditiesByOrganisation()
    } catch (error) {
      this.$log.error('Fetch commodities by organisation error', error)
    }
  }
}
