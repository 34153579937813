import { RecordDetail } from '@hectare/platform.clients.inventory'
import { CreateListing } from '@hectare/platform.clients.trading'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'
import {
  TradeAvailabilityRecordFormModel,
  TradingEditRecordFormModel
} from '~/lib/types/trading/trade-availability-record'

export class TradeAvailability extends ApiInventory {
  createNewMarketListing(postModel: CreateListing): Promise<OpenAPIV3.ResponseObject> {
    return this.post('/trading/listings', postModel)
  }

  createTradeAvailabilityRecords(postModel: TradeAvailabilityRecordFormModel): Promise<RecordDetail[]> {
    return this.post('/inventory/trade-availability', postModel)
  }

  deleteTradeAvailabilityRecord(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/inventory/trade-availability/${id}`)
  }

  editTradeAvailabilityRecord(id: string, postModel: TradingEditRecordFormModel): Promise<RecordDetail> {
    return this.patch(`/inventory/trade-availability/${id}`, postModel)
  }
}
