import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0632f846 = () => interopDefault(import('../src/pages/accept-terms.vue' /* webpackChunkName: "pages/accept-terms" */))
const _4c921880 = () => interopDefault(import('../src/pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _a83d9cd4 = () => interopDefault(import('../src/pages/help-centre/index.vue' /* webpackChunkName: "pages/help-centre/index" */))
const _621993cb = () => interopDefault(import('../src/pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _8a7f81c4 = () => interopDefault(import('../src/pages/locale.vue' /* webpackChunkName: "pages/locale" */))
const _6f4bc47f = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _9a92c986 = () => interopDefault(import('../src/pages/marketing-preferences.vue' /* webpackChunkName: "pages/marketing-preferences" */))
const _90816f72 = () => interopDefault(import('../src/pages/inventory/adjustment/index.vue' /* webpackChunkName: "pages/inventory/adjustment/index" */))
const _4ac430e0 = () => interopDefault(import('../src/pages/inventory/fields.vue' /* webpackChunkName: "pages/inventory/fields" */))
const _2cd36a27 = () => interopDefault(import('../src/pages/inventory/harvest-plan/index.vue' /* webpackChunkName: "pages/inventory/harvest-plan/index" */))
const _2efde19c = () => interopDefault(import('../src/pages/inventory/new/index.vue' /* webpackChunkName: "pages/inventory/new/index" */))
const _1190dcb4 = () => interopDefault(import('../src/pages/inventory/storage.vue' /* webpackChunkName: "pages/inventory/storage" */))
const _39a61cc8 = () => interopDefault(import('../src/pages/trading/alert-preferences/index.vue' /* webpackChunkName: "pages/trading/alert-preferences/index" */))
const _3652dc90 = () => interopDefault(import('../src/pages/trading/availability/index.vue' /* webpackChunkName: "pages/trading/availability/index" */))
const _7d12c5dd = () => interopDefault(import('../src/pages/trading/inventory/index.vue' /* webpackChunkName: "pages/trading/inventory/index" */))
const _3fb2064d = () => interopDefault(import('../src/pages/trading/market-insights/index.vue' /* webpackChunkName: "pages/trading/market-insights/index" */))
const _37c710a8 = () => interopDefault(import('../src/pages/trading/market-listing/index.vue' /* webpackChunkName: "pages/trading/market-listing/index" */))
const _eaeb1738 = () => interopDefault(import('../src/pages/trading/wanted/index.vue' /* webpackChunkName: "pages/trading/wanted/index" */))
const _3bd80ac6 = () => interopDefault(import('../src/pages/valuation-tool/livestock/index.vue' /* webpackChunkName: "pages/valuation-tool/livestock/index" */))
const _6328f5ca = () => interopDefault(import('../src/pages/inventory/actions/incomplete/index.vue' /* webpackChunkName: "pages/inventory/actions/incomplete/index" */))
const _01ba4234 = () => interopDefault(import('../src/pages/inventory/auth/business-invite.vue' /* webpackChunkName: "pages/inventory/auth/business-invite" */))
const _1dfcafb5 = () => interopDefault(import('../src/pages/inventory/auth/forgot-password.vue' /* webpackChunkName: "pages/inventory/auth/forgot-password" */))
const _387548dc = () => interopDefault(import('../src/pages/inventory/auth/login/index.vue' /* webpackChunkName: "pages/inventory/auth/login/index" */))
const _06ec728a = () => interopDefault(import('../src/pages/inventory/auth/logout.vue' /* webpackChunkName: "pages/inventory/auth/logout" */))
const _194e07da = () => interopDefault(import('../src/pages/inventory/auth/organisation.vue' /* webpackChunkName: "pages/inventory/auth/organisation" */))
const _37eb9ed9 = () => interopDefault(import('../src/pages/inventory/auth/reset-password.vue' /* webpackChunkName: "pages/inventory/auth/reset-password" */))
const _63bf10a6 = () => interopDefault(import('../src/pages/inventory/auth/set-password.vue' /* webpackChunkName: "pages/inventory/auth/set-password" */))
const _37ffcd98 = () => interopDefault(import('../src/pages/inventory/auth/sign-in-without-password.vue' /* webpackChunkName: "pages/inventory/auth/sign-in-without-password" */))
const _d1dc3f4a = () => interopDefault(import('../src/pages/inventory/internal/business-accounts/index.vue' /* webpackChunkName: "pages/inventory/internal/business-accounts/index" */))
const _105bf45a = () => interopDefault(import('../src/pages/inventory/manage/account.vue' /* webpackChunkName: "pages/inventory/manage/account" */))
const _3a37ddd1 = () => interopDefault(import('../src/pages/inventory/manage/businesses.vue' /* webpackChunkName: "pages/inventory/manage/businesses" */))
const _9e6aaf00 = () => interopDefault(import('../src/pages/inventory/manage/team.vue' /* webpackChunkName: "pages/inventory/manage/team" */))
const _e8c22c1c = () => interopDefault(import('../src/pages/inventory/movements/all/index.vue' /* webpackChunkName: "pages/inventory/movements/all/index" */))
const _6900f720 = () => interopDefault(import('../src/pages/inventory/movements/external/index.vue' /* webpackChunkName: "pages/inventory/movements/external/index" */))
const _3cdd66d0 = () => interopDefault(import('../src/pages/inventory/movements/intakes/index.vue' /* webpackChunkName: "pages/inventory/movements/intakes/index" */))
const _91b73e3c = () => interopDefault(import('../src/pages/inventory/movements/internal/index.vue' /* webpackChunkName: "pages/inventory/movements/internal/index" */))
const _2da97870 = () => interopDefault(import('../src/pages/inventory/movements/invalid/index.vue' /* webpackChunkName: "pages/inventory/movements/invalid/index" */))
const _4f83319e = () => interopDefault(import('../src/pages/inventory/movements/new/index.vue' /* webpackChunkName: "pages/inventory/movements/new/index" */))
const _6b3cae82 = () => interopDefault(import('../src/pages/inventory/planting/new/index.vue' /* webpackChunkName: "pages/inventory/planting/new/index" */))
const _8d7a25dc = () => interopDefault(import('../src/pages/inventory/temp/external-bid/index.vue' /* webpackChunkName: "pages/inventory/temp/external-bid/index" */))
const _5c28fa3a = () => interopDefault(import('../src/pages/inventory/trades/new/index.vue' /* webpackChunkName: "pages/inventory/trades/new/index" */))
const _5c8f81b2 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/upload/index.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/upload/index" */))
const _02db1aa2 = () => interopDefault(import('../src/pages/trading/availability/breakdown/index.vue' /* webpackChunkName: "pages/trading/availability/breakdown/index" */))
const _74912b04 = () => interopDefault(import('../src/pages/trading/contracts/premium-contracts/index.vue' /* webpackChunkName: "pages/trading/contracts/premium-contracts/index" */))
const _6fac129a = () => interopDefault(import('../src/pages/trading/contracts/sales-contracts/index.vue' /* webpackChunkName: "pages/trading/contracts/sales-contracts/index" */))
const _79a364a2 = () => interopDefault(import('../src/pages/trading/contracts/storage-contracts/index.vue' /* webpackChunkName: "pages/trading/contracts/storage-contracts/index" */))
const _31e88aa4 = () => interopDefault(import('../src/pages/trading/inventory/new/index.vue' /* webpackChunkName: "pages/trading/inventory/new/index" */))
const _871b0bca = () => interopDefault(import('../src/pages/trading/manage/business-setup/index.vue' /* webpackChunkName: "pages/trading/manage/business-setup/index" */))
const _0ee5ef33 = () => interopDefault(import('../src/pages/trading/market-listing/bids/index.vue' /* webpackChunkName: "pages/trading/market-listing/bids/index" */))
const _45b7a7f9 = () => interopDefault(import('../src/pages/trading/market-listing/new/index.vue' /* webpackChunkName: "pages/trading/market-listing/new/index" */))
const _072a6496 = () => interopDefault(import('../src/pages/trading/wanted/new/index.vue' /* webpackChunkName: "pages/trading/wanted/new/index" */))
const _ee3cf6c0 = () => interopDefault(import('../src/pages/trading/wanted/seller/index.vue' /* webpackChunkName: "pages/trading/wanted/seller/index" */))
const _7e9070c2 = () => interopDefault(import('../src/pages/inventory/auth/login/create-account.vue' /* webpackChunkName: "pages/inventory/auth/login/create-account" */))
const _d260615e = () => interopDefault(import('../src/pages/inventory/manage/business-setup/business-units/index.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/business-units/index" */))
const _646ecf65 = () => interopDefault(import('../src/pages/inventory/manage/business-setup/fields.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/fields" */))
const _6ac7dc1a = () => interopDefault(import('../src/pages/inventory/manage/business-setup/location-groups/index.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/location-groups/index" */))
const _79004fde = () => interopDefault(import('../src/pages/inventory/manage/business-setup/stores.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/stores" */))
const _672f8f14 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/completed.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/completed" */))
const _ec906a10 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/loads.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/loads" */))
const _37ad2698 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/new-load.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/new-load" */))
const _b33922fc = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/pending.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/pending" */))
const _38193241 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/upcoming.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/upcoming" */))
const _78cd7be6 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/carrier/upload.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/carrier/upload" */))
const _5ccb7be6 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/organiser/completed.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/organiser/completed" */))
const _14b37330 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/organiser/loads.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/organiser/loads" */))
const _551bb528 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/organiser/new-load.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/organiser/new-load" */))
const _5ebe641c = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/organiser/pending.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/organiser/pending" */))
const _5587c0d1 = () => interopDefault(import('../src/pages/logistics/proof-of-delivery/organiser/upcoming.vue' /* webpackChunkName: "pages/logistics/proof-of-delivery/organiser/upcoming" */))
const _4d9b26cf = () => interopDefault(import('../src/pages/trading/contracts/premium-contracts/new/index.vue' /* webpackChunkName: "pages/trading/contracts/premium-contracts/new/index" */))
const _7ed29084 = () => interopDefault(import('../src/pages/trading/contracts/sales-contracts/new/index.vue' /* webpackChunkName: "pages/trading/contracts/sales-contracts/new/index" */))
const _4e15221a = () => interopDefault(import('../src/pages/trading/contracts/storage-contracts/new/index.vue' /* webpackChunkName: "pages/trading/contracts/storage-contracts/new/index" */))
const _46ecd1be = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77fb71f9 = () => interopDefault(import('../src/pages/inventory/manage/business-setup/business-units/_id.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/business-units/_id" */))
const _bc4815ca = () => interopDefault(import('../src/pages/inventory/manage/business-setup/location-groups/_id.vue' /* webpackChunkName: "pages/inventory/manage/business-setup/location-groups/_id" */))
const _670c1734 = () => interopDefault(import('../src/pages/trading/contracts/premium-contracts/_id.vue' /* webpackChunkName: "pages/trading/contracts/premium-contracts/_id" */))
const _21c7ec4a = () => interopDefault(import('../src/pages/trading/contracts/sales-contracts/_id.vue' /* webpackChunkName: "pages/trading/contracts/sales-contracts/_id" */))
const _040310eb = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/index.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/index" */))
const _2dcbde96 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/trade-confirmation.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/trade-confirmation" */))
const _546095d8 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/options/_optionId/advance-pay-offer.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/options/_optionId/advance-pay-offer" */))
const _7557b365 = () => interopDefault(import('../src/pages/trading/market-listing/bids/_bidId/options/_optionId/trade-summary.vue' /* webpackChunkName: "pages/trading/market-listing/bids/_bidId/options/_optionId/trade-summary" */))
const _4be5ccdc = () => interopDefault(import('../src/pages/inventory/harvest-plan/_id/index.vue' /* webpackChunkName: "pages/inventory/harvest-plan/_id/index" */))
const _93712a66 = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/create-bid.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/create-bid" */))
const _a947798a = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/offers/index.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/offers/index" */))
const _5a70ffa1 = () => interopDefault(import('../src/pages/inventory/harvest-plan/_id/loads/new/index.vue' /* webpackChunkName: "pages/inventory/harvest-plan/_id/loads/new/index" */))
const _3ce98ade = () => interopDefault(import('../src/pages/trading/market-listing/_marketListingId/bids/_bidId/offer/index.vue' /* webpackChunkName: "pages/trading/market-listing/_marketListingId/bids/_bidId/offer/index" */))
const _23104d64 = () => interopDefault(import('../src/pages/help-centre/_category/index.vue' /* webpackChunkName: "pages/help-centre/_category/index" */))
const _9bffe61a = () => interopDefault(import('../src/pages/inventory/_id.vue' /* webpackChunkName: "pages/inventory/_id" */))
const _471dd136 = () => interopDefault(import('../src/pages/inventory/_id/index.vue' /* webpackChunkName: "pages/inventory/_id/index" */))
const _5839ec08 = () => interopDefault(import('../src/pages/inventory/_id/adjustment/index.vue' /* webpackChunkName: "pages/inventory/_id/adjustment/index" */))
const _d3bd50f6 = () => interopDefault(import('../src/pages/inventory/_id/fields.vue' /* webpackChunkName: "pages/inventory/_id/fields" */))
const _7c3f8945 = () => interopDefault(import('../src/pages/inventory/_id/overview.vue' /* webpackChunkName: "pages/inventory/_id/overview" */))
const _73092942 = () => interopDefault(import('../src/pages/inventory/_id/storage.vue' /* webpackChunkName: "pages/inventory/_id/storage" */))
const _2d7ecc0d = () => interopDefault(import('../src/pages/inventory/_id/timeline.vue' /* webpackChunkName: "pages/inventory/_id/timeline" */))
const _38e88d4a = () => interopDefault(import('../src/pages/inventory/_id/trades.vue' /* webpackChunkName: "pages/inventory/_id/trades" */))
const _784cbbef = () => interopDefault(import('../src/pages/inventory/_id/trades/new/index.vue' /* webpackChunkName: "pages/inventory/_id/trades/new/index" */))
const _185e327f = () => interopDefault(import('../src/pages/inventory/_id/actions/incomplete/index.vue' /* webpackChunkName: "pages/inventory/_id/actions/incomplete/index" */))
const _25ad9810 = () => interopDefault(import('../src/pages/inventory/_id/internal/business-accounts/index.vue' /* webpackChunkName: "pages/inventory/_id/internal/business-accounts/index" */))
const _2a7e9f4f = () => interopDefault(import('../src/pages/inventory/_id/manage/account.vue' /* webpackChunkName: "pages/inventory/_id/manage/account" */))
const _bae75a88 = () => interopDefault(import('../src/pages/inventory/_id/manage/businesses.vue' /* webpackChunkName: "pages/inventory/_id/manage/businesses" */))
const _89b02caa = () => interopDefault(import('../src/pages/inventory/_id/manage/team.vue' /* webpackChunkName: "pages/inventory/_id/manage/team" */))
const _30a9d01d = () => interopDefault(import('../src/pages/inventory/_id/movements/all/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/all/index" */))
const _fe967db6 = () => interopDefault(import('../src/pages/inventory/_id/movements/external/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/external/index" */))
const _c5d1b57a = () => interopDefault(import('../src/pages/inventory/_id/movements/intakes/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/intakes/index" */))
const _6c599d97 = () => interopDefault(import('../src/pages/inventory/_id/movements/internal/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/internal/index" */))
const _b69dc71a = () => interopDefault(import('../src/pages/inventory/_id/movements/invalid/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/invalid/index" */))
const _7d494d5c = () => interopDefault(import('../src/pages/inventory/_id/movements/new/index.vue' /* webpackChunkName: "pages/inventory/_id/movements/new/index" */))
const _74c8c5bd = () => interopDefault(import('../src/pages/inventory/_id/temp/external-bid/index.vue' /* webpackChunkName: "pages/inventory/_id/temp/external-bid/index" */))
const _62c6accc = () => interopDefault(import('../src/pages/inventory/_id/manage/business-setup/fields.vue' /* webpackChunkName: "pages/inventory/_id/manage/business-setup/fields" */))
const _095ab6a4 = () => interopDefault(import('../src/pages/inventory/_id/manage/business-setup/business-units/_id.vue' /* webpackChunkName: "pages/inventory/_id/manage/business-setup/business-units/_id" */))
const _ed29cdf4 = () => interopDefault(import('../src/pages/inventory/_id/manage/business-setup/location-groups/_id.vue' /* webpackChunkName: "pages/inventory/_id/manage/business-setup/location-groups/_id" */))
const _098aadc4 = () => interopDefault(import('../src/pages/inventory/_id/_id.vue' /* webpackChunkName: "pages/inventory/_id/_id" */))
const _6106ad95 = () => interopDefault(import('../src/pages/help-centre/_category/_article.vue' /* webpackChunkName: "pages/help-centre/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-terms",
    component: _0632f846,
    name: "accept-terms"
  }, {
    path: "/dashboard",
    component: _4c921880,
    name: "dashboard"
  }, {
    path: "/help-centre",
    component: _a83d9cd4,
    name: "help-centre"
  }, {
    path: "/inventory",
    component: _621993cb,
    name: "inventory"
  }, {
    path: "/locale",
    component: _8a7f81c4,
    name: "locale"
  }, {
    path: "/maintenance",
    component: _6f4bc47f,
    name: "maintenance"
  }, {
    path: "/marketing-preferences",
    component: _9a92c986,
    name: "marketing-preferences"
  }, {
    path: "/inventory/adjustment",
    component: _90816f72,
    name: "inventory-adjustment"
  }, {
    path: "/inventory/fields",
    component: _4ac430e0,
    name: "inventory-fields"
  }, {
    path: "/inventory/harvest-plan",
    component: _2cd36a27,
    name: "inventory-harvest-plan"
  }, {
    path: "/inventory/new",
    component: _2efde19c,
    name: "inventory-new"
  }, {
    path: "/inventory/storage",
    component: _1190dcb4,
    name: "inventory-storage"
  }, {
    path: "/trading/alert-preferences",
    component: _39a61cc8,
    name: "trading-alert-preferences"
  }, {
    path: "/trading/availability",
    component: _3652dc90,
    name: "trading-availability"
  }, {
    path: "/trading/inventory",
    component: _7d12c5dd,
    name: "trading-inventory"
  }, {
    path: "/trading/market-insights",
    component: _3fb2064d,
    name: "trading-market-insights"
  }, {
    path: "/trading/market-listing",
    component: _37c710a8,
    name: "trading-market-listing"
  }, {
    path: "/trading/wanted",
    component: _eaeb1738,
    name: "trading-wanted"
  }, {
    path: "/valuation-tool/livestock",
    component: _3bd80ac6,
    name: "valuation-tool-livestock"
  }, {
    path: "/inventory/actions/incomplete",
    component: _6328f5ca,
    name: "inventory-actions-incomplete"
  }, {
    path: "/inventory/auth/business-invite",
    component: _01ba4234,
    name: "inventory-auth-business-invite"
  }, {
    path: "/inventory/auth/forgot-password",
    component: _1dfcafb5,
    name: "inventory-auth-forgot-password"
  }, {
    path: "/inventory/auth/login",
    component: _387548dc,
    name: "inventory-auth-login"
  }, {
    path: "/inventory/auth/logout",
    component: _06ec728a,
    name: "inventory-auth-logout"
  }, {
    path: "/inventory/auth/organisation",
    component: _194e07da,
    name: "inventory-auth-organisation"
  }, {
    path: "/inventory/auth/reset-password",
    component: _37eb9ed9,
    name: "inventory-auth-reset-password"
  }, {
    path: "/inventory/auth/set-password",
    component: _63bf10a6,
    name: "inventory-auth-set-password"
  }, {
    path: "/inventory/auth/sign-in-without-password",
    component: _37ffcd98,
    name: "inventory-auth-sign-in-without-password"
  }, {
    path: "/inventory/internal/business-accounts",
    component: _d1dc3f4a,
    name: "inventory-internal-business-accounts"
  }, {
    path: "/inventory/manage/account",
    component: _105bf45a,
    name: "inventory-manage-account"
  }, {
    path: "/inventory/manage/businesses",
    component: _3a37ddd1,
    name: "inventory-manage-businesses"
  }, {
    path: "/inventory/manage/team",
    component: _9e6aaf00,
    name: "inventory-manage-team"
  }, {
    path: "/inventory/movements/all",
    component: _e8c22c1c,
    name: "inventory-movements-all"
  }, {
    path: "/inventory/movements/external",
    component: _6900f720,
    name: "inventory-movements-external"
  }, {
    path: "/inventory/movements/intakes",
    component: _3cdd66d0,
    name: "inventory-movements-intakes"
  }, {
    path: "/inventory/movements/internal",
    component: _91b73e3c,
    name: "inventory-movements-internal"
  }, {
    path: "/inventory/movements/invalid",
    component: _2da97870,
    name: "inventory-movements-invalid"
  }, {
    path: "/inventory/movements/new",
    component: _4f83319e,
    name: "inventory-movements-new"
  }, {
    path: "/inventory/planting/new",
    component: _6b3cae82,
    name: "inventory-planting-new"
  }, {
    path: "/inventory/temp/external-bid",
    component: _8d7a25dc,
    name: "inventory-temp-external-bid"
  }, {
    path: "/inventory/trades/new",
    component: _5c28fa3a,
    name: "inventory-trades-new"
  }, {
    path: "/logistics/proof-of-delivery/upload",
    component: _5c8f81b2,
    name: "logistics-proof-of-delivery-upload"
  }, {
    path: "/trading/availability/breakdown",
    component: _02db1aa2,
    name: "trading-availability-breakdown"
  }, {
    path: "/trading/contracts/premium-contracts",
    component: _74912b04,
    name: "trading-contracts-premium-contracts"
  }, {
    path: "/trading/contracts/sales-contracts",
    component: _6fac129a,
    name: "trading-contracts-sales-contracts"
  }, {
    path: "/trading/contracts/storage-contracts",
    component: _79a364a2,
    name: "trading-contracts-storage-contracts"
  }, {
    path: "/trading/inventory/new",
    component: _31e88aa4,
    name: "trading-inventory-new"
  }, {
    path: "/trading/manage/business-setup",
    component: _871b0bca,
    name: "trading-manage-business-setup"
  }, {
    path: "/trading/market-listing/bids",
    component: _0ee5ef33,
    name: "trading-market-listing-bids"
  }, {
    path: "/trading/market-listing/new",
    component: _45b7a7f9,
    name: "trading-market-listing-new"
  }, {
    path: "/trading/wanted/new",
    component: _072a6496,
    name: "trading-wanted-new"
  }, {
    path: "/trading/wanted/seller",
    component: _ee3cf6c0,
    name: "trading-wanted-seller"
  }, {
    path: "/inventory/auth/login/create-account",
    component: _7e9070c2,
    name: "inventory-auth-login-create-account"
  }, {
    path: "/inventory/manage/business-setup/business-units",
    component: _d260615e,
    name: "inventory-manage-business-setup-business-units"
  }, {
    path: "/inventory/manage/business-setup/fields",
    component: _646ecf65,
    name: "inventory-manage-business-setup-fields"
  }, {
    path: "/inventory/manage/business-setup/location-groups",
    component: _6ac7dc1a,
    name: "inventory-manage-business-setup-location-groups"
  }, {
    path: "/inventory/manage/business-setup/stores",
    component: _79004fde,
    name: "inventory-manage-business-setup-stores"
  }, {
    path: "/logistics/proof-of-delivery/carrier/completed",
    component: _672f8f14,
    name: "logistics-proof-of-delivery-carrier-completed"
  }, {
    path: "/logistics/proof-of-delivery/carrier/loads",
    component: _ec906a10,
    name: "logistics-proof-of-delivery-carrier-loads"
  }, {
    path: "/logistics/proof-of-delivery/carrier/new-load",
    component: _37ad2698,
    name: "logistics-proof-of-delivery-carrier-new-load"
  }, {
    path: "/logistics/proof-of-delivery/carrier/pending",
    component: _b33922fc,
    name: "logistics-proof-of-delivery-carrier-pending"
  }, {
    path: "/logistics/proof-of-delivery/carrier/upcoming",
    component: _38193241,
    name: "logistics-proof-of-delivery-carrier-upcoming"
  }, {
    path: "/logistics/proof-of-delivery/carrier/upload",
    component: _78cd7be6,
    name: "logistics-proof-of-delivery-carrier-upload"
  }, {
    path: "/logistics/proof-of-delivery/organiser/completed",
    component: _5ccb7be6,
    name: "logistics-proof-of-delivery-organiser-completed"
  }, {
    path: "/logistics/proof-of-delivery/organiser/loads",
    component: _14b37330,
    name: "logistics-proof-of-delivery-organiser-loads"
  }, {
    path: "/logistics/proof-of-delivery/organiser/new-load",
    component: _551bb528,
    name: "logistics-proof-of-delivery-organiser-new-load"
  }, {
    path: "/logistics/proof-of-delivery/organiser/pending",
    component: _5ebe641c,
    name: "logistics-proof-of-delivery-organiser-pending"
  }, {
    path: "/logistics/proof-of-delivery/organiser/upcoming",
    component: _5587c0d1,
    name: "logistics-proof-of-delivery-organiser-upcoming"
  }, {
    path: "/trading/contracts/premium-contracts/new",
    component: _4d9b26cf,
    name: "trading-contracts-premium-contracts-new"
  }, {
    path: "/trading/contracts/sales-contracts/new",
    component: _7ed29084,
    name: "trading-contracts-sales-contracts-new"
  }, {
    path: "/trading/contracts/storage-contracts/new",
    component: _4e15221a,
    name: "trading-contracts-storage-contracts-new"
  }, {
    path: "/",
    component: _46ecd1be,
    name: "index"
  }, {
    path: "/inventory/manage/business-setup/business-units/:id?",
    component: _77fb71f9,
    name: "inventory-manage-business-setup-business-units-id"
  }, {
    path: "/inventory/manage/business-setup/location-groups/:id?",
    component: _bc4815ca,
    name: "inventory-manage-business-setup-location-groups-id"
  }, {
    path: "/trading/contracts/premium-contracts/:id?",
    component: _670c1734,
    name: "trading-contracts-premium-contracts-id"
  }, {
    path: "/trading/contracts/sales-contracts/:id?",
    component: _21c7ec4a,
    name: "trading-contracts-sales-contracts-id"
  }, {
    path: "/trading/market-listing/bids/:bidId",
    component: _040310eb,
    name: "trading-market-listing-bids-bidId"
  }, {
    path: "/trading/market-listing/bids/:bidId/trade-confirmation",
    component: _2dcbde96,
    name: "trading-market-listing-bids-bidId-trade-confirmation"
  }, {
    path: "/trading/market-listing/bids/:bidId/options/:optionId/advance-pay-offer",
    component: _546095d8,
    name: "trading-market-listing-bids-bidId-options-optionId-advance-pay-offer"
  }, {
    path: "/trading/market-listing/bids/:bidId/options/:optionId/trade-summary",
    component: _7557b365,
    name: "trading-market-listing-bids-bidId-options-optionId-trade-summary"
  }, {
    path: "/inventory/harvest-plan/:id",
    component: _4be5ccdc,
    name: "inventory-harvest-plan-id"
  }, {
    path: "/trading/market-listing/:marketListingId?/create-bid",
    component: _93712a66,
    name: "trading-market-listing-marketListingId-create-bid"
  }, {
    path: "/trading/market-listing/:marketListingId?/offers",
    component: _a947798a,
    name: "trading-market-listing-marketListingId-offers"
  }, {
    path: "/inventory/harvest-plan/:id?/loads/new",
    component: _5a70ffa1,
    name: "inventory-harvest-plan-id-loads-new"
  }, {
    path: "/trading/market-listing/:marketListingId?/bids/:bidId?/offer",
    component: _3ce98ade,
    name: "trading-market-listing-marketListingId-bids-bidId-offer"
  }, {
    path: "/help-centre/:category",
    component: _23104d64,
    name: "help-centre-category"
  }, {
    path: "/inventory/:id",
    component: _9bffe61a,
    children: [{
      path: "",
      component: _471dd136,
      name: "inventory-id"
    }, {
      path: "adjustment",
      component: _5839ec08,
      name: "inventory-id-adjustment"
    }, {
      path: "fields",
      component: _d3bd50f6,
      name: "inventory-id-fields"
    }, {
      path: "overview",
      component: _7c3f8945,
      name: "inventory-id-overview"
    }, {
      path: "storage",
      component: _73092942,
      name: "inventory-id-storage"
    }, {
      path: "timeline",
      component: _2d7ecc0d,
      name: "inventory-id-timeline"
    }, {
      path: "trades",
      component: _38e88d4a,
      name: "inventory-id-trades",
      children: [{
        path: "new",
        component: _784cbbef,
        name: "inventory-id-trades-new"
      }]
    }, {
      path: "actions/incomplete",
      component: _185e327f,
      name: "inventory-id-actions-incomplete"
    }, {
      path: "internal/business-accounts",
      component: _25ad9810,
      name: "inventory-id-internal-business-accounts"
    }, {
      path: "manage/account",
      component: _2a7e9f4f,
      name: "inventory-id-manage-account"
    }, {
      path: "manage/businesses",
      component: _bae75a88,
      name: "inventory-id-manage-businesses"
    }, {
      path: "manage/team",
      component: _89b02caa,
      name: "inventory-id-manage-team"
    }, {
      path: "movements/all",
      component: _30a9d01d,
      name: "inventory-id-movements-all"
    }, {
      path: "movements/external",
      component: _fe967db6,
      name: "inventory-id-movements-external"
    }, {
      path: "movements/intakes",
      component: _c5d1b57a,
      name: "inventory-id-movements-intakes"
    }, {
      path: "movements/internal",
      component: _6c599d97,
      name: "inventory-id-movements-internal"
    }, {
      path: "movements/invalid",
      component: _b69dc71a,
      name: "inventory-id-movements-invalid"
    }, {
      path: "movements/new",
      component: _7d494d5c,
      name: "inventory-id-movements-new"
    }, {
      path: "temp/external-bid",
      component: _74c8c5bd,
      name: "inventory-id-temp-external-bid"
    }, {
      path: "manage/business-setup/fields",
      component: _62c6accc,
      name: "inventory-id-manage-business-setup-fields"
    }, {
      path: "manage/business-setup/business-units/:id?",
      component: _095ab6a4,
      name: "inventory-id-manage-business-setup-business-units-id"
    }, {
      path: "manage/business-setup/location-groups/:id?",
      component: _ed29cdf4,
      name: "inventory-id-manage-business-setup-location-groups-id"
    }, {
      path: ":id",
      component: _098aadc4,
      name: "inventory-id-id"
    }]
  }, {
    path: "/help-centre/:category?/:article",
    component: _6106ad95,
    name: "help-centre-category-article"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
