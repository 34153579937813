import { get } from 'lodash'

import { offlineDataAuth } from '~/lib/enums/cookies/offline-data-keys'
import { modulesMap } from '~/lib/enums/permissions.config'
import { hasAppWebForcedLogout } from '~/lib/utility/feature-flags'
import { clearOfflineStorage, getOfflineStorage, setOfflineStorage } from '~/lib/utility/offline-storage'
import ResetPasswordEntity, {
  GraindexResetPasswordEntity
} from '~/modules/inventory/lib/entities/inventory-reset-your-password'
import PasswordChangeEntity from '~/modules/inventory/lib/entities/inventory-update-your-password'
import { ApiModel } from '~/plugins/api-logistics/api-model'
import { isNative } from '~/plugins/native/capacitor'
import { identifyUser as identifyUserHotJar } from '~/services/hotjar'
import { identifyUser as identifyUserPostHog } from '~/services/posthog'

const initialState = () => {
  return {
    accessToken: null,
    idToken: null,
    refreshToken: null,
    isRefreshingToken: false,
    profile: {},
    loginApi: new ApiModel(),
    getUserProfileApi: new ApiModel(),
    logoutApi: new ApiModel(),
    refreshTokenApi: new ApiModel(),
    passwordChangeApi: new ApiModel(new PasswordChangeEntity().model),
    saveProfileApi: new ApiModel(),
    emailRegisteredApi: new ApiModel(),
    passwordResetApi: new ApiModel(),
    passwordResetConfirmationApi: new ApiModel(new ResetPasswordEntity().model),
    customerSignUpApi: new ApiModel(),
    hasExistingOrganisation: false,
    isFetchingRefreshToken: false,
    emailAddressRegistrationState: false,
    impersonateApi: new ApiModel(),
    impersonateBusiness: {
      id: null,
      name: null,
      token: null
    },
    selectedOrganisation: {
      id: null,
      name: null
    },
    verifyMagicLinkApi: new ApiModel(),
    tokenLoginApi: new ApiModel(),
    changeMarketplacePasswordApi: new ApiModel(new GraindexResetPasswordEntity().model),
    graindexCredentials: null
  }
}

export const state = () => initialState()

export const getters = {
  hasTokens: state => {
    return state.accessToken !== null && state.idToken !== null
  },

  getCurrentUserIsOwner: state => {
    return state.profile?.groups?.includes('owner')
  },

  getRefreshToken: state => {
    return state.refreshToken
  },

  getCurrentProfile(state) {
    return state.profile
  },

  getCurrentOrg(state) {
    return state.profile?.organisation ?? {}
  },

  getUserProfiles(state) {
    return state.loginApi?.response.data.profiles ?? []
  },

  hasExistingOrganisation: state => {
    return state.hasExistingOrganisation
  },

  emailAddressRegistrationState: state => {
    return state.emailAddressRegistrationState
  },

  isImpersonatingBusiness: state => {
    return state.impersonateBusiness.id !== null
  },

  getUserCountryIso(state) {
    return state.profile?.countryISO ?? 'gb'
  },

  getHasPermission: state => permissionsPath => {
    if (!permissionsPath) return true

    const profilePermissions = state.profile?.access ?? {}

    return get(profilePermissions, permissionsPath)
  },

  getHasModule: state => moduleId => {
    if (!moduleId) return true

    const userModules = state.profile?.organisation?.modules ?? []

    return userModules.some(module => module.id === moduleId)
  },

  getHasRole: state => role => {
    return state.profile?.roles.includes(role)
  },

  loginPath: (_state, getters, _rootState) => {
    const query = Object.fromEntries(new URLSearchParams(window.location.search))
    let redirectPath = ''

    if (getters.getHasRole('sys-admin')) {
      return '/inventory/internal/business-accounts'
    }

    if (query?.redirect && query.redirect !== 'gdx') {
      return query.redirect
    }

    if (getters.getHasModule(modulesMap.logisticsPodOrganiser)) {
      return '/logistics/proof-of-delivery/organiser/loads'
    }

    if (getters.getHasModule(modulesMap.logisticsPodCarrier)) {
      return '/logistics/proof-of-delivery/carrier/loads'
    }

    if (
      getters.getHasModule(modulesMap.tradingInsightsLivestock) &&
      !getters.getHasModule(modulesMap.tradingCore) &&
      !isNative
    ) {
      return '/valuation-tool/livestock'
    }

    const isTradingUser =
      getters.getHasModule(modulesMap.tradingCore) || getters.getHasModule(modulesMap.tradingBuyers)

    const isInventoryUser = getters.getHasModule(modulesMap.inventoryCore)
    const hasFullAccount = isTradingUser && isInventoryUser

    if (isTradingUser || isNative) {
      redirectPath = '/trading/market-insights'
    }

    if (!isNative && (isInventoryUser || hasFullAccount)) {
      redirectPath = '/inventory'
    }

    return redirectPath
  },

  getUserAssignedBusinessUnits: state => {
    return state.profile?.businessUnits ?? []
  },

  getHasFeature: state => featureId => {
    if (!featureId) return true

    const userFeatures = state.profile?.access?.features ?? {}

    if (userFeatures[featureId] === undefined) return true

    return !!userFeatures[featureId]
  },

  hasFullAccount: state => {
    return state.profile?.access?.menu
  },

  getGraindexCredentials: state => {
    return state.graindexCredentials
  }
}

export const actions = {
  loadExistingTokens({ state, commit, dispatch }) {
    const tokens = {
      idToken: null,
      accessToken: null,
      impersonateToken: null
    }

    const offlineData = {
      refreshToken: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_REFRESH_TOKEN),
      accessToken: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ACCESS_TOKEN),
      idToken: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ID_TOKEN),
      profile: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_PROFILE),
      impersonate: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_IMPERSONATE),
      selectedOrganisation: getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_SELECTED_ORG)
    }

    const offlineDataImpersonate = offlineData.impersonate
      ? JSON.parse(offlineData.impersonate)
      : offlineData.impersonate

    if (offlineData.selectedOrganisation) {
      commit('setSelectedOrganisation', JSON.parse(offlineData.selectedOrganisation))
    }

    if (offlineData.refreshToken) {
      commit('setRefreshToken', {
        refreshToken: offlineData.refreshToken,
        setStateOnly: true
      })
    }

    if (offlineData.accessToken) {
      tokens.accessToken = offlineData.accessToken
    }

    if (offlineData.idToken) {
      tokens.idToken = offlineData.idToken
    }

    if (offlineDataImpersonate && offlineDataImpersonate.token) {
      tokens.impersonateToken = offlineDataImpersonate.token
    }

    if (offlineData.profile) {
      const profile = JSON.parse(offlineData.profile)

      commit('setProfile', {
        profile,
        setStateOnly: true
      })
    }

    if (tokens.accessToken && tokens.idToken) {
      commit('setAccessAndIdToken', {
        accessToken: tokens.accessToken,
        idToken: tokens.idToken,
        setStateOnly: true
      })
    }

    if (tokens.impersonateToken) {
      commit('setImpersonateBusiness', offlineDataImpersonate)
    }

    if (offlineData.impersonate && !state.impersonateBusiness.token) {
      dispatch('impersonateBusiness', offlineDataImpersonate)
    }
  },

  async logout({ dispatch }) {
    this.$gtm.push({ event: 'live-chat-remove' })

    dispatch('clearFilterCookies')
    dispatch('endImpersonateBusiness')
    dispatch('clearSelectedOrganisation')
    await dispatch('clearAccessAndIdToken')
    this.$redirect.to('/inventory/auth/login')
  },

  async clearAccessAndIdToken({ state, commit }) {
    if (!state.accessToken) {
      return true
    }

    if (!state.idToken) {
      return true
    }

    await this.$api
      .auth(state.logoutApi)
      .useStorePath('inventory.auth.logoutApi')
      .logout(state.accessToken)
      .catch(error => {
        this.$log.error('Logout error', error)
      })

    commit('setAccessAndIdToken', { accessToken: null, idToken: null, shouldClearTokens: true })
    commit('setRefreshToken', { refreshToken: null, shouldClearToken: true })
    commit('setProfile', { profile: null, shouldClearToken: true })
  },

  clearFilterCookies() {
    const currentCookies = Object.keys(this.$cookie.getAll()).filter(cookie => cookie.includes('filters-'))

    for (const cookie of currentCookies) {
      this.$cookie.remove(cookie)
    }
  },

  async login({ state, commit, dispatch, rootGetters }, requestModel, config) {
    await this.$api.auth(state.loginApi).useStorePath('inventory.auth.loginApi').login(requestModel, config)

    await dispatch('postLoginAction')
  },

  async loginViaToken({ state, dispatch }, token) {
    await this.$api.auth(state.tokenLoginApi).useStorePath('inventory.auth.tokenLoginApi').loginViaToken(token)

    await dispatch('postLoginAction', 'tokenLoginApi')

    const response = state.tokenLoginApi.response.data

    await dispatch('setUserProfile', response)
  },

  async postLoginAction({ state, getters, commit, dispatch, rootGetters }, stateLoginApiKey = 'loginApi') {
    const stateApi = state[stateLoginApiKey]

    if (get(stateApi, 'response.data.tokens.access')) {
      commit('setAccessAndIdToken', {
        accessToken: stateApi.response.data.tokens.access,
        idToken: stateApi.response.data.tokens.id
      })

      commit('setRefreshToken', { refreshToken: stateApi.response.data.tokens.refresh })

      if (stateLoginApiKey !== 'tokenLoginApi') {
        // token login returns the profile in the response
        await dispatch('getCurrentProfile')
      }
    }

    const app = rootGetters['inventory/app/getApp']

    if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
      this.$sentry.configureScope(scope => {
        scope.setUser({
          email: app.activeProfile.email,
          contactNumber: `${app.activeProfile.phone?.callingCode}${app.activeProfile.phone?.number}`,
          organisation: `${app.currentOrganisation?.name} - ${app.currentOrganisation?.id} `,
          id: app.activeProfile.id,
          username: app.activeProfile.name
        })
      })
    }

    identifyUserPostHog(state.profile.id, state.profile.email, `${app.currentOrganisation?.name}`)
    identifyUserHotJar(state.profile.id, state.profile.email, `${app.currentOrganisation?.name}`)

    this.$gtm.push({ username: state.profile.email })
    this.$gtm.push({ event: 'user-data-set' })

    if (
      !getters.getHasModule(modulesMap.logisticsPodOrganiser) &&
      !getters.getHasModule(modulesMap.logisticsPodCarrier)
    ) {
      this.$gtm.push({ event: 'live-chat-show' })
    }
  },

  setUserProfile({ state, commit }, profileApiResponse) {
    if (profileApiResponse.user) {
      commit('setProfile', {
        profile: {
          ...profileApiResponse.user,
          access: {
            ...profileApiResponse.access,
            features: profileApiResponse.features
          },
          organisation: profileApiResponse.organisation ?? null
        }
      })
    }
  },

  async getCurrentProfile({ state, commit, dispatch }) {
    await this.$api.auth(state.getUserProfileApi).useStorePath('inventory.auth.getUserProfileApi').getMyProfile()

    const profileApiResponse = state.getUserProfileApi.response.data

    dispatch('setUserProfile', profileApiResponse)
  },

  async fetchRefreshToken({ state, commit, dispatch }) {
    if (state.isRefreshingToken) {
      return
    }

    commit('setIsFetchingRefreshToken', true)

    const existingRefreshToken = getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_REFRESH_TOKEN)

    if (!existingRefreshToken && !!hasAppWebForcedLogout) {
      dispatch('logout')
      return
    }

    try {
      const { response } = await this.$api
        .auth(state.refreshTokenApi)
        .useStorePath('inventory.auth.refreshTokenApi')
        .fetchRefreshToken(existingRefreshToken)

      commit('setAccessAndIdToken', {
        accessToken: response.data.tokens.access,
        idToken: response.data.tokens.id
      })

      await dispatch('getCurrentProfile')
    } catch (error) {
      this.$log.error('Refresh token error', error)

      dispatch('logout')
    } finally {
      commit('setIsFetchingRefreshToken', false)
    }
  },

  async signUp({ state }, customerSignUpFormData) {
    const { response } = await this.$api
      .auth(state.customerSignUpApi)
      .useStorePath('inventory.auth.customerSignUpApi')
      .customerSignUp(customerSignUpFormData)

    return response
  },

  async signUpFromInvite({ state }, { customerSignUpFormData, inviteId }) {
    const { response } = await this.$api
      .auth(state.customerSignUpApi)
      .useStorePath('inventory.auth.customerSignUpApi')
      .customerSignUpFromInvite(customerSignUpFormData, inviteId)

    return response
  },

  async checkEmailRegistrationState({ state, commit }, postModel) {
    try {
      const { response } = await this.$api
        .auth(state.emailRegisteredApi)
        .useStorePath('inventory.auth.emailRegisteredApi')
        .isEmailRegistered(postModel)

      commit('setHasExistingOrganisation', response.data.organisation)
      commit('setEmailAddressRegistrationState', response.data.status)

      return response
    } catch (error) {
      this.$log.error('Error getting email address registration state', error)
    }
  },

  async requestPasswordReset(_, userIdentifier) {
    const { response } = await this.$api
      .auth(state.passwordResetApi)
      .useStorePath('inventory.auth.passwordResetApi')
      .requestPasswordReset(userIdentifier)

    return response
  },

  async changePassword({ state }, postModel) {
    const { response } = await this.$api
      .auth(state.passwordChangeApi)
      .useStorePath('inventory.auth.passwordChangeApi')
      .changePassword(postModel)

    return response
  },

  clearPasswordChangeApiState({ commit }) {
    commit('setPasswordChangeApiState', new ApiModel(new PasswordChangeEntity().model))
  },

  async impersonateBusiness({ state, commit, dispatch }, business = { id: null, name: null, countryISO: null }) {
    dispatch('clearFilterCookies')

    try {
      await this.$api
        .organisations(state.impersonateApi)
        .useStorePath('inventory.auth.impersonateApi')
        .impersonateOrganisation(business.id)

      const impersonateParams = {
        ...business,
        token: state.impersonateApi.response.data?.token
      }

      await commit('setImpersonateBusiness', impersonateParams)

      await dispatch('getCurrentProfile')

      dispatch(
        'inventory/app/fetchCoreConfig',
        { countryCode: impersonateParams.countryISO, forceUpdate: true },
        { root: true }
      )

      await dispatch('inventory/record/fetchCommoditiesByOrganisation', { root: true })

      setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_IMPERSONATE, JSON.stringify(impersonateParams))
    } catch (error) {
      this.$log.error('Error impersonating business', error)
      this.$notify.errorGeneric()
    }
  },

  async endImpersonateBusiness({ state, commit, dispatch }) {
    dispatch('clearFilterCookies')

    // Not passing in business results in clearing to null
    await commit('setImpersonateBusiness')

    await dispatch('getCurrentProfile')

    clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_IMPERSONATE)
  },

  clearSelectedOrganisation() {
    clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_SELECTED_ORG)
  },

  async verifyMagicLink({ state, commit, dispatch }, postModel) {
    await this.$api
      .auth(state.verifyMagicLinkApi)
      .useStorePath('inventory.auth.verifyMagicLinkApi')
      .verifyMagicLink(postModel)

    await dispatch('postLoginAction', 'verifyMagicLinkApi')
  },

  async changeMarketplacePassword({ state, commit, dispatch }, postModel) {
    await this.$api
      .auth(state.changeMarketplacePasswordApi)
      .useStorePath('inventory.auth.changeMarketplacePasswordApi')
      .changeMarketplacePassword({
        identifier: postModel.identifier,
        oldPassword: postModel.oldPassword,
        newPassword: postModel.newPassword,
        confirmNewPassword: postModel.confirmNewPassword
      })

    // We want to avoid showing an error message when the user
    // gets redirected to the login on the postLogin action.
    // This error appears because the response from login is saved
    // in the store and doesn't get deleted.
    commit('clearLoginApiResponse')

    await dispatch('postLoginAction', 'changeMarketplacePasswordApi')
    commit('clearGraindexCredentials')
  },

  setGraindexCredentials({ state, commit, dispatch }, credentials) {
    commit('saveGraindexCredentials', credentials)

    const fiveMinutes = 300000

    setTimeout(() => {
      commit('clearGraindexCredentials')
    }, fiveMinutes)
  },

  updateUserTokens({ state, commit }, tokens) {
    commit('setAccessAndIdToken', {
      accessToken: tokens.access,
      idToken: tokens.id
    })

    commit('setRefreshToken', { refreshToken: tokens.refresh })
  }
}

export const mutations = {
  setAccessAndIdToken(state, { accessToken, idToken, setStateOnly = false, shouldClearTokens = false }) {
    state.accessToken = accessToken
    state.idToken = idToken

    if (setStateOnly) {
      return
    }

    if (shouldClearTokens) {
      clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ACCESS_TOKEN)
      clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ID_TOKEN)
    } else {
      setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ACCESS_TOKEN, accessToken)
      setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ID_TOKEN, idToken)
    }
  },

  setRefreshToken(state, { refreshToken, setStateOnly = false, shouldClearToken = false }) {
    state.refreshToken = refreshToken
    state.refreshTokenApi.model.refreshToken = refreshToken

    if (setStateOnly) {
      return
    }

    if (shouldClearToken) {
      clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_REFRESH_TOKEN)
    } else {
      setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_REFRESH_TOKEN, refreshToken)
    }
  },

  setProfile(state, { profile, setStateOnly = false, shouldClearToken = false }) {
    state.profile = profile

    if (setStateOnly) {
      return
    }

    if (shouldClearToken) {
      clearOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_PROFILE)
    } else if (profile) {
      setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_PROFILE, JSON.stringify(profile))
    }
  },

  setHasExistingOrganisation(state, organisation) {
    state.hasExistingOrganisation = organisation ?? false
  },

  setEmailAddressRegistrationState(state, addressRegistrationState) {
    state.emailAddressRegistrationState = addressRegistrationState
  },

  setPasswordChangeApiState(state, newPasswordChangeApiState) {
    state.passwordChangeApi = newPasswordChangeApiState
  },

  setImpersonateBusiness(state, business = {}) {
    state.impersonateBusiness.id = business.id ?? null
    state.impersonateBusiness.name = business.name ?? null
    state.impersonateBusiness.token = business.token ?? null
  },

  setIsFetchingRefreshToken(state, isFetchingRefreshToken) {
    state.isRefreshingToken = isFetchingRefreshToken
  },

  setSelectedOrganisation(state, org) {
    state.selectedOrganisation = org
    setOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_SELECTED_ORG, JSON.stringify(org))
  },

  saveGraindexCredentials(state, credentials) {
    state.graindexCredentials = credentials
  },

  clearGraindexCredentials(state) {
    state.graindexCredentials = null
  },

  clearLoginApiResponse(state) {
    state.loginApi = new ApiModel()
  }
}
