import locale5f43b444 from '../../src/locales/en-GB.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en-GB","silentFallbackWarn":true,"numberFormats":{"en-GB":{"currency":{"style":"currency","currency":"GBP"},"currencyNoDecimals":{"style":"currency","currency":"GBP","minimumFractionDigits":0,"maximumFractionDigits":0}},"en-US":{"currency":{"style":"currency","currency":"USD"},"currencyNoDecimals":{"style":"currency","currency":"USD","minimumFractionDigits":0,"maximumFractionDigits":0}},"en-ZA":{"currency":{"style":"currency","currency":"ZAR"}},"pt-BR":{"currency":{"style":"currency","currency":"EUR"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en-GB","name":"British English","iso":"en-GB","file":"en-GB.ts","isCatchallLocale":true},{"code":"en-ZA","name":"South African English","iso":"en-ZA","file":"en-ZA.yml"},{"code":"pt-BR","name":"Portugues do Brasil","iso":"pt-BR","file":"pt-BR.yml"},{"code":"en-US","name":"US English","iso":"en-US","file":"en-US.yml"}],
  defaultLocale: "en-GB",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/home/runner/actions-runner/_work/farmto-client/farmto-client/src/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"farmto-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"all","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true,"syncLocale":true,"syncMessages":false},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"code":"en-GB","name":"British English","iso":"en-GB","file":"en-GB.ts","isCatchallLocale":true},{"code":"en-ZA","name":"South African English","iso":"en-ZA","file":"en-ZA.yml"},{"code":"pt-BR","name":"Portugues do Brasil","iso":"pt-BR","file":"pt-BR.yml"},{"code":"en-US","name":"US English","iso":"en-US","file":"en-US.yml"}],
  localeCodes: ["en-GB","en-ZA","pt-BR","en-US"],
  additionalMessages: [],
}

export const localeMessages = {
  'en-GB.ts': () => Promise.resolve(locale5f43b444),
  'en-ZA.yml': () => import('../../src/locales/en-ZA.yml' /* webpackChunkName: "lang-en-ZA.yml" */),
  'pt-BR.yml': () => import('../../src/locales/pt-BR.yml' /* webpackChunkName: "lang-pt-BR.yml" */),
  'en-US.yml': () => import('../../src/locales/en-US.yml' /* webpackChunkName: "lang-en-US.yml" */),
}
