import { PatchStorageContract } from '@hectare/platform.clients.inventory'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'
import { CreateStorageContract } from '~/lib/types/form/storage-contract'

export class StorageContractService extends ApiInventory {
  createStorageContract(postModel: CreateStorageContract): Promise<OpenAPIV3.ResponseObject> {
    return this.post('/inventory/storage-contracts', postModel)
  }

  getStorageContract(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.get(`/inventory/storage-contracts/${id}`)
  }

  getStorageContracts(query: Record<string, string>): Promise<OpenAPIV3.ResponseObject> {
    const queryParams = new URLSearchParams(query)

    return this.get(`/inventory/storage-contracts?${queryParams.toString()}`)
  }

  editStorageContract(id: string, postModel: PatchStorageContract): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/inventory/storage-contracts/${id}`, postModel)
  }

  deleteStorageContract(id: string): Promise<OpenAPIV3.ResponseObject> {
    return this.delete(`/inventory/storage-contracts/${id}`)
  }
}
