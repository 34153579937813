var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"flex justify-center items-center h-16 w-16 rounded-full text-xl",class:[
    {
      'bg-green-100': _setup.props.variant === 'success',
      'bg-red-100': _setup.props.variant === 'error',
      'bg-primary-50': _setup.props.variant === 'information',
      'bg-gray-100': _setup.props.variant === 'default'
    },
    _setup.props.iconBackgroundClass
  ],attrs:{"data-testid":"iconBadge"}},[_c(_setup.FontAwesomeIcon,{class:[
      {
        'text-green-600': _setup.props.variant === 'success',
        'text-red-600': _setup.props.variant === 'error',
        'text-primary-600': _setup.props.variant === 'information',
        'text-gray-600': _setup.props.variant === 'default'
      },
      _setup.props.iconClass
    ],attrs:{"icon":_setup.props.icon,"fixedWidth":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }