import {
  CreateLoadByCarrierSchema,
  CreateLoadByOrganiserSchema,
  LoadDetailSchema,
  SendLoadToCarrierSchema,
  SendLoadToDriverSchema,
  UploadProofOfDeliverySchema
} from '@hectare/platform.clients.logistics'
import { OpenAPIV3 } from 'openapi-types'

import { ApiInventory } from '~/lib/api-inventory'

type UserType = 'carrier' | 'organiser' | 'driver'

export class ProofOfDeliveryService extends ApiInventory {
  createNewLoad(postModel: CreateLoadByOrganiserSchema[]): Promise<CreateLoadByOrganiserSchema[]> {
    return this.post('/logistics/organiser/loads', postModel)
  }

  createNewLoadsCarrier(postModel: CreateLoadByCarrierSchema[]): Promise<OpenAPIV3.ResponseObject> {
    return this.post('/logistics/carrier/loads', postModel)
  }

  fetchDriverLoadDetail(loadId: string): Promise<LoadDetailSchema> | void {
    return this.get(`/logistics/driver/loads/${loadId}`)
  }

  uploadProofOfDelivery(proof: UploadProofOfDeliverySchema, loadId: string): Promise<OpenAPIV3.ResponseObject> {
    return this.patch(`/logistics/driver/loads/${loadId}`, [proof])
  }

  uploadProofOfDeliveryCarrier(postModel: UploadProofOfDeliverySchema[]) {
    return this.patch(`/logistics/carrier/loads`, postModel)
  }

  deleteLoads(loadIds: Record<string, string>[]) {
    return this.post('/logistics/organiser/loads/delete', loadIds)
  }

  sendToCarriers(loadDetail: SendLoadToCarrierSchema[]) {
    return this.post('/logistics/organiser/loads/send-to-carrier', loadDetail)
  }

  sendToDriver(loadDetails: SendLoadToDriverSchema[]) {
    return this.post('/logistics/carrier/loads/send-to-driver', loadDetails)
  }

  getListOfLoads(userType: UserType, loadIds: string) {
    return this.get(`/logistics/${userType}/loads/list?ids=${loadIds}`)
  }
}
