// Refers to Inventory Business connections
import { ApiModel } from '~/plugins/api-logistics/api-model'

const initialState = () => {
  return {
    businessConnectionsApi: new ApiModel(),
    updateBusinessConnection: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  isBusinessConnectionsLoading: state => {
    return state.inventory?.businessConnections?.connectedOrganisationsApi?.isLoading
  },

  carrierBusinessConnections: (state, getters) => {
    return getters.businessConnections
      .filter(businessConnection => businessConnection.relationship?.includes('carrier'))
      .map(businessConnection => ({
        label: businessConnection.organisationName,
        value: businessConnection.organisationId
      }))
  },

  listAllBusinessConnections: (state, getters) => {
    return getters.businessConnections.map(businessConnection => ({
      label: businessConnection.organisationName,
      value: businessConnection.organisationId
    }))
  },

  businessConnections: state => {
    const allBusinessConnections = state.businessConnectionsApi.response.data?.connectedOrganisations ?? []

    // If the user doesn't know the owner contact,
    // we don't want to show them it so fallback to first contact they know
    return allBusinessConnections.map(connectedOrganisation => {
      const contacts = connectedOrganisation?.contacts ?? []
      const ownerContact = connectedOrganisation.ownerContact

      const hasOwnerAsContact = contacts.some(contact => contact.id === ownerContact.id)

      return {
        ...connectedOrganisation,
        ownerContact: hasOwnerAsContact ? ownerContact : contacts[0] ?? null
      }
    })
  },

  getBusinessConnectionsRadioOptions: (state, getters) => {
    return getters.businessConnections.map(item => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
        address: item.address,
        // TODO: This is a specific contact format for business connections
        // that is currently hardcoded in a few places, i.e John Smith (john@email.com)
        // make sure to pass this when working on api integration
        contact: `${item.contact} (${item.email})`
      }
    })
  }
}

export const mutations = {
  reset(state) {
    this.$log.debug('Resetting filters store')

    Object.assign(state, initialState())
  }
}

export const actions = {
  async fetchBusinessConnections({ state, getters }, queryParams = { limit: 200, offset: 0 }) {
    await this.$api
      .businessConnections(state.businessConnectionsApi)
      .useStorePath('inventory.businessConnections.businessConnectionsApi')
      .fetchBusinessConnections(queryParams)
  },

  async updateBusinessConnection({ state }, { id, postModel }) {
    await this.$api
      .businessConnections(state.updateBusinessConnection)
      .useStorePath('inventory.businessConnections.updateBusinessConnection')
      .updateBusinessConnection(id, postModel)
  }
}
