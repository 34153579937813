// User profile'access' permissions paths
export const permissionsMap = {
  admin: 'administrator',

  viewAccount: 'account.account.read',
  writeAccount: 'account.account.write',

  viewBusiness: 'account.business.read',
  writeBusiness: 'account.business.write',

  viewTeams: 'account.teams.read',
  writeTeams: 'account.teams.write',

  // Currently only owners have so can use to block by owners only:
  viewBusinessUnits: 'account.business-units.read',
  writeBusinessUnits: 'account.business-units.write',

  viewInventoryRecords: 'inventory.records.read',
  writeInventoryRecords: 'inventory.records.write',

  viewInventoryStorage: 'inventory.storage.read',
  writeInventoryStorage: 'inventory.storage.write',

  viewInventoryMovements: 'inventory.movements.read',
  writeInventoryMovements: 'inventory.movements.write',

  viewInventoryAdjustments: 'inventory.adjustments.read',
  writeInventoryAdjustments: 'inventory.adjustments.write',

  viewInventoryStorageContracts: 'inventory.storagecontracts.read',
  writeInventoryStorageContracts: 'inventory.storagecontracts.write',

  viewTradingContracts: 'trading.contracts.read',
  writeTradingContracts: 'trading.contracts.write',

  viewTradingListings: 'trading.listings.read',
  writeTradingListings: 'trading.listings.write',

  viewTradingPosition: 'trading.position.read',
  writeTradingPosition: 'trading.position.write',

  viewTradingBids: 'trading.bids.read',
  writeTradingBids: 'trading.bids.write',

  viewTradingInventory: 'trading.inventory.read',
  writeTradingInventory: 'trading.inventory.write',

  viewTradingInsightsGrain: 'trading.insights.grain',
  viewTradingInsightsLivestock: 'trading.insights.livestock',

  viewTradingMarket: 'trading.market.read',

  viewLogisticsOrganiser: 'logistics.organiser.read',
  writelogisticsOrganiser: 'logistics.organiser.write',

  viewLogisticsCarrier: 'logistics.carrier.read',
  writeLogisticsCarrier: 'logistics.carrier.write'
}

export const modulesMap = {
  inventoryCore: 'inventory:core',
  inventoryStorage: 'inventory:storage',
  logisticsCore: 'logistics:core',
  logisticsPodOrganiser: 'logistics:pod:organiser',
  logisticsPodCarrier: 'logistics:pod:carrier',
  tradingCore: 'trading:core',
  tradingInsightsGrain: 'trading:insights:grain',
  tradingInsightsLivestock: 'trading:insights:livestock',
  tradingBuyers: 'trading:buyers'
}

export const featuresMap = {
  tradingInsightsAds: 'trading:insights:ads',
  tradingOpenNetwork: 'trading:opennetwork'
}
