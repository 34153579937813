import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import { MovementPeriod } from '../types/date'
import { Option } from '../types/options'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

export const date = dayjs

export function isDateValid(date: string): boolean {
  return dayjs(date).isValid()
}

export function isToday(date: string): boolean {
  return dayjs(date).isToday()
}

export function isYesterday(date: string): boolean {
  const yesterday = dayjs().add(-1, 'day')

  return dayjs(date).isSame(yesterday, 'date')
}

export function displayDateTime(dateTime: string) {
  return dayjs(dateTime).format('MMM D, YYYY, HH:mm')
}

export function getTime(utcDate: string) {
  return dayjs(utcDate).local().format('HH:mm')
}

export function getYearOptions(limit = 6): Option<string>[] {
  const currentYear = new Date().getFullYear() - 1
  const years = []

  for (let i = 0; i <= limit; i++) {
    const newYear = currentYear + i

    years.push({
      label: newYear.toString(),
      value: newYear.toString()
    })
  }
  return years
}

export function getIntervalUntilTime(utcDate: string) {
  const duration = dayjs.duration(dayjs.utc().diff(utcDate))
  const isCountingDown = dayjs.utc().isBefore(utcDate)

  const days = Math.abs(duration.days())
  const hours = Math.abs(duration.hours())
  const minutes = Math.abs(duration.minutes())
  const seconds = Math.abs(duration.seconds())

  return {
    days,
    hours,
    minutes,
    seconds,
    isCountingDown
  }
}

export function getISOString(date: Dayjs) {
  return date.toISOString()
}

export function getCalendarTime(date: Dayjs) {
  return date.calendar()
}

export function displayDateOrMonthRange(movementPeriod: MovementPeriod) {
  const startDateUTC = movementPeriod.startDateUTC
  const endDateUTC = movementPeriod.endDateUTC

  const hasSameMonth = dayjs.utc(startDateUTC).isSame(dayjs.utc(endDateUTC), 'month')
  const hasSameDate = dayjs.utc(startDateUTC).isSame(dayjs.utc(endDateUTC), 'day')
  const hasSameYear = dayjs.utc(startDateUTC).isSame(dayjs.utc(endDateUTC), 'year')
  const displayDateRange = movementPeriod.type === 'date'

  if (displayDateRange) {
    return hasSameDate && hasSameMonth
      ? dayjs.utc(startDateUTC).local().format('D MMM YYYY')
      : `${dayjs
          .utc(startDateUTC)
          .local()
          .format(`D MMM${hasSameYear ? '' : ' YYYY'}`)} - ${dayjs.utc(endDateUTC).local().format('D MMM YYYY')}`
  } else {
    return hasSameMonth
      ? dayjs.utc(startDateUTC).local().format('MMM YYYY')
      : `${dayjs
          .utc(startDateUTC)
          .local()
          .format(`MMM${hasSameYear ? '' : ' YYYY'}`)} - ${dayjs.utc(endDateUTC).local().format('MMM YYYY')}`
  }
}

export interface MovementMonths {
  month?: string
  year: string
  monthNumber: number
}

export const getStartMonthDate = (month: MovementMonths) =>
  dayjs().year(parseInt(month.year)).month(month.monthNumber).startOf('month').utc().toISOString()

export const getEndMonthDate = (month: MovementMonths) =>
  dayjs().year(parseInt(month.year)).month(month.monthNumber).endOf('month').utc().toISOString()

export default dayjs
