type DataLayerBaseParams = 'is_mobile' | 'platform' | 'location'

type DataLayerBidParams = 'bid_id' | 'bid_max_weight' | 'bid_min_weight' | 'bid_price'

type DataLayerListingParams =
  | 'listing_commodity'
  | 'listing_commodity_grade'
  | 'listing_commodity_type'
  | 'listing_harvest_year'
  | 'listing_id'
  | 'listing_max_weight'
  | 'listing_min_weight'

type DataLayerUserParams = 'user_id' | 'user_product' | 'user_role'

type DataLayerCalculatePositionParams =
  | 'crop'
  | 'currently_sold'
  | 'date'
  | 'desired_position'
  | 'grade'
  | 'harvest_year'
  | 'price_source'
  | 'region'

type DataLayerTargetPriceParams = 'region' | 'alert_set' | 'location'

type DataLayerMarketNewsParams = 'subject' | 'market_update_id'

type DataLayerRelistParams = 'reason'

type DataLayerMarketingQuestionParams = 'answer'

export type AssuranceLookupStatus = 'success' | 'failure' | 'later'

type DataLayerAssuranceLookupStatusParams = 'status'

type DataLayerParams =
  | DataLayerBaseParams
  | DataLayerBidParams
  | DataLayerCalculatePositionParams
  | DataLayerListingParams
  | DataLayerUserParams
  | DataLayerMarketNewsParams
  | DataLayerTargetPriceParams
  | DataLayerRelistParams
  | DataLayerMarketingQuestionParams
  | DataLayerAssuranceLookupStatusParams

type DataLayerEvents =
  | 'alert_teammate_added'
  | 'bid_acceptance_accept_trade'
  | 'bid_acceptance_completed'
  | 'bid_acceptance_started'
  | 'bid_creation_completed'
  | 'bid_creation_started'
  | 'bid_reopen_completed'
  | 'bid_reopen_started'
  | 'bid_seen'
  | 'calculate_my_position_completed'
  | 'collection_receipt_completed'
  | 'collection_receipt_image_started'
  | 'collection_receipt_started'
  | 'contract_upload_completed'
  | 'contract_upload_started'
  | 'ex_farm_table_focus'
  | 'finance_bank_details_popup_clicked'
  | 'finance_continue_without_selected'
  | 'finance_eligible_bid_opened'
  | 'finance_faq_clicked'
  | 'finance_info_link_clicked'
  | 'finance_info_link_clicked'
  | 'finance_show_me_more_clicked'
  | 'finance_trade_completed'
  | 'finance_trade_confirmation_clicked'
  | 'finance_trade_started'
  | 'finance_trading_calculator_completed'
  | 'flexible_tonnage_info_focus'
  | 'futures_prices_focus'
  | 'listing_commodity_entry_completed'
  | 'listing_creation_completed'
  | 'listing_creation_started'
  | 'listing_seen'
  | 'listing_spec_info_completed'
  | 'listing_spec_info_expanded'
  | 'listing_spec_info_started'
  | 'market_insights_highlights_carousel_scrolled'
  | 'market_news_clicked'
  | 'market_update_bubble_closed'
  | 'market_update_bubble_opened'
  | 'marketing_question'
  | 'pending_account_listing_creation_clicked'
  | 'pending_finance_need_help_clicked'
  | 'price_offers_seen'
  | 'recent_trade_table_focus'
  | 'relist_completed'
  | 'request_access_form_completed'
  | 'seller_click_buyer_profile'
  | 'seller_hover_buyer'
  | 'set_target_price_complete'
  | 'set_target_price_started'
  | 'trade_summary_download'
  | 'view_all_trading_expanded'
  | 'wanted_creation_completed'
  | 'wanted_creation_started'

type DataLayerUpdate = { event?: DataLayerEvents } & { [key in DataLayerParams]?: unknown }

export const pushToDataLayer = (dataLayerUpdate: DataLayerUpdate) => {
  if (!window.dataLayer || !window.dataLayer.push) {
    return
  }

  window.dataLayer.push(dataLayerUpdate)
}

export const clearDataLayer = () => {
  if (!window.dataLayer || !window.dataLayer.push) {
    return
  }

  window.dataLayer.push(function () {
    this.reset()
  })
}
